import React, { useEffect, useState } from "react"
import { Header, Footer, Input, SimpleButton, CoinsDropdown, Loading } from "components"
import {AnnouncementModal} from "../../modals"

import "./Swap.scss"
import { ReactComponent as Change } from "assets/icons/exchange.svg"
import imgMainBgSrc from "assets/images/main-bg.webp"
import {Link, useSearchParams} from "react-router-dom"
import { mixins, moneyFormatter, routes } from "../../utils"
import { coins, coinsLimit } from "./coins"
import { apiCoin } from "../../service/api/apiCoinGecko"
import axios from "axios"
import cn from "classnames"
import useDebounce from "../../utils/useDebounce"
import Chart from "react-apexcharts"
import { ethers } from "ethers"
import { api } from "../../service/api/api"
import {
  approveAddress,
  contractAddressApeApprove,
  contractAddressLinkApprove,
  contractAddressUniApprove,
  contractAddressUsdcApprove,
  contractAddressWbtcApprove,
  contractAddressWethApprove,
} from "../../abi"
import abiApprove from "../../abi/abiApprove.json"
import abiUsdcApprove from "../../abi/abiUsdcApprove.json"
import abiUniApprove from "../../abi/abiUniApprove.json"
import abiLinkApprove from "../../abi/abiLinkApprove.json"
import abiWbtcApprove from "../../abi/abiWbtcApprove.json"
import abiApeApprove from "../../abi/abiApeApprove.json"
import abiWethApprove from "../../abi/abiWethApprove.json"
import { useMedia } from "use-media"
import {coinsBsc, coinsBscLimit} from "./coinsBsc";
import {useAccount} from "wagmi";
import {getChainId} from "@wagmi/core";
import {config} from "../../index";
import {ConnectKitButton} from "connectkit";

const zeroPad = (num, places) => String(num).padEnd(places + 1, "0")

export const Swap = (): JSX.Element => {
  const [tabs, setTabs] = useState("swap")
  const [tabsChart, setTabsChart] = useState("send")
  const [slippage, setSlippage] = useState(2.5)
  const [stat, setStat] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [sendInput, setSendInput] = useState("1")
  const [sendCurrency, setSendCurrency] = useState(coins[0])
  const [receiveCurrency, setReceiveCurrency] = useState(coins[2])
  const [receiveInput, setReceiveInput] = useState("")
  const [pricePerOneLeft, setPricePerOneLeft] = useState(0)
  const [pricePerOneRight, setPricePerOneRight] = useState(0)
  const [rate, setRate] = useState<any>("")
  const [expires, setExpires] = useState("7 days")
  const [isRateDefault, setIsRateDefault] = useState(false)
  const { address } = useAccount();
  const chainId = getChainId(config)
  const debouncedRate = useDebounce(rate, 500)
  const [txDetails, setTxDetails] = useState({
    to: null,
    data: null,
    value: null,
  })
  const isM = useMedia({ maxWidth: mixins.m })
  const [stat1, setStat1] = useState<any>(undefined)
  const [stat2, setStat2] = useState<any>(undefined)
  const busd = 1000000000000000000
  const [walletBalanceEth, setWalletBalanceEth] = useState(0)
  const [walletBalanceUsdt, setWalletBalanceUsdt] = useState(0)
  const [walletBalanceUsdc, setWalletBalanceUsdc] = useState(0)
  const [walletBalanceUni, setWalletBalanceUni] = useState(0)
  const [walletBalanceLink, setWalletBalanceLink] = useState(0)
  const [walletBalanceWbtc, setWalletBalanceWbtc] = useState(0)
  const [walletBalanceApe, setWalletBalanceApe] = useState(0)
  const [walletBalanceWeth, setWalletBalanceWeth] = useState(0)
  const [search] = useSearchParams()
  const changeValues = () => {
    const firstCurr = sendCurrency
    const firstInput = sendInput
    const secondCurr = receiveCurrency
    const secondInput = receiveInput

    setReceiveCurrency(firstCurr)
    setSendCurrency(secondCurr)
    setReceiveInput(firstInput)
    setSendInput(secondInput)
  }

  useEffect(() => {
    apiCoin.getStat().then(rOther => {
      setStat(rOther)
      console.log(rOther)
    })
  }, [])

  useEffect(() => {
    if (chainId === 56) {
      if (tabs === "limit") {
        setSendCurrency(coinsBscLimit[0])
        setReceiveCurrency(coinsBscLimit[1])
        setReceiveInput("")
        setSendInput("1")
      } else {
        setSendCurrency(coinsBsc[0])
        setReceiveCurrency(coinsBsc[2])
        setReceiveInput("")
        setSendInput("1")
      }
    } else {
      if (tabs === "limit") {
        setSendCurrency(coinsLimit[0])
        setReceiveCurrency(coinsLimit[1])
        setReceiveInput("")
        setSendInput("1")
      } else {
        setSendCurrency(coins[0])
        setReceiveCurrency(coins[2])
        setReceiveInput("")
        setSendInput("1")
      }
    }
  }, [tabs, chainId])


  useEffect(() => {
    apiCoin
      .getChartDaily(sendCurrency.id)
      .then(rOther => {
        setStat1({
          options: {
            title: {
              text: `${sendCurrency.name}`,
              align: "left",
            },
            chart: {
              id: "area-datetime",
              type: "area",
              zoom: {
                autoScaleYaxis: true,
              },
            },
            grid: {
              show: true,
              borderColor: '#90A4AE',
              strokeDashArray: 0,
              position: 'back',
              xaxis: {
                lines: {
                  show: false
                }
              },
              yaxis: {
                lines: {
                  show: true
                }
              },
              row: {
                colors: undefined,
                opacity: 0.5
              },
              padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 10
              },
            },
            dataLabels: {
              enabled: false,
              style: {
                colors: ['#01ff76', '#01ff76', '#01ff76']
              }
            },
            style: {
              colors: ['#01ff76', '#01ff76', '#01ff76']
            },
            markers: {
              size: 0,
              style: "hollow",
            },
            xaxis: {
              type: "datetime",
              tickAmount: 6,
              labels: {
                datetimeUTC: false,
              },
            },
            tooltip: {
              style: {
                color: '#000'
              },
              x: {
                formatter: function (val) {
                  return `${new Date(val).toLocaleDateString()} ${new Date(val).toLocaleTimeString()}`
                },
              },
              y: {
                formatter: function (val) {
                  return val.toFixed(8)
                },
              },
            },
            fill: {
              type: "gradient",
              colors: ['#01ff76', '#01ff76', '#01ff76'],
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.9,
                gradientToColors: ['#01ff76'],
                opacityTo: 0.1,
                stops: [0, 100],
              },
            },
            yaxis: {
              labels: {
                formatter: function (val) {
                  return val.toFixed(0)
                },
              },
            },
          },
          series: [
            {
              name: "Price",
              data: rOther.prices,
              color: '#01ff76'
            },
          ],
        })
      })
      .finally(() => setLoading(false))
    apiCoin.getChartDaily(receiveCurrency.id).then(rOther => {
      setStat2({
        options: {
          title: {
            text: `${receiveCurrency.name}`,
            align: "left",
          },
          chart: {
            id: "basic-bar",
            type: "area",
            zoom: {
              autoScaleYaxis: true,
            },
          },
          style: {
            colors: ['#01ff76', '#01ff76', '#01ff76']
          },
          dataLabels: {
            enabled: false,
            style: {
              colors: ['#01ff76', '#01ff76', '#01ff76']
            }
          },
          markers: {
            size: 0,
            style: "hollow",
          },
          xaxis: {
            type: "datetime",
            tickAmount: 6,
            labels: {
              datetimeUTC: false,
            },
          },
          tooltip: {
            x: {
              formatter: function (val) {
                return `${new Date(val).toLocaleDateString()} ${new Date(val).toLocaleTimeString()}`
              },
            },
          },
          fill: {
            type: "gradient",
            colors: ['#01ff76', '#01ff76', '#01ff76'],
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              gradientToColors: ['#01ff76'],
              stops: [0, 100],
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(5)
              },
            },
          },
        },
        series: [
          {
            name: "Price",
            data: rOther.prices,
          },
        ],
      })
    })
  }, [sendCurrency, receiveCurrency])

  const getAllInfo = () => {
    // @ts-ignore
    const { ethereum } = window
    const provider = new ethers.providers.Web3Provider(ethereum)
    api.getBalance(`${address}`).then(r => {
      setWalletBalanceEth(Number(r.result) / busd)
    })
    {
      const tokenContractUsdt = new ethers.Contract(approveAddress, abiApprove, provider)
      tokenContractUsdt.balanceOf(address).then(r => {
        setWalletBalanceUsdt(Number(r) / 1000000)
      })
      const tokenContractUsdc = new ethers.Contract(contractAddressUsdcApprove, abiUsdcApprove, provider)

      tokenContractUsdc.balanceOf(address).then(r => {
        setWalletBalanceUsdc(Number(r) / 1000000)
      })

      const tokenContractUni = new ethers.Contract(contractAddressUniApprove, abiUniApprove, provider)

      tokenContractUni.balanceOf(address).then(r => {
        setWalletBalanceUni(Number(r) / busd)
      })
      const tokenContractLink = new ethers.Contract(contractAddressLinkApprove, abiLinkApprove, provider)

      tokenContractLink.balanceOf(address).then(r => {
        setWalletBalanceLink(Number(r) / busd)
      })

      const tokenContract = new ethers.Contract(contractAddressWbtcApprove, abiWbtcApprove, provider)

      tokenContract.balanceOf(address).then(r => {
        setWalletBalanceWbtc(Number(r) / 100000000)
      })
      const tokenContractApe = new ethers.Contract(contractAddressApeApprove, abiApeApprove, provider)

      tokenContractApe.balanceOf(address).then(r => {
        setWalletBalanceApe(Number(r) / busd)
      })
      const tokenContractWeth = new ethers.Contract(contractAddressWethApprove, abiWethApprove, provider)

      tokenContractWeth.balanceOf(address).then(r => {
        setWalletBalanceWeth(Number(r) / busd)
      })
    }
  }

  useEffect(() => {
    if (address) {
      getAllInfo()
    }
  }, [address])

  useEffect(() => {
    // apiInch
    //   .quote(chainId || 1,{
    //     fromTokenAddress: sendCurrency.address,
    //     toTokenAddress: receiveCurrency.address,
    //     amount: Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals)) || 0,
    //   })
    //   .then(r => {
    //     setReceiveInput((Number(r.toTokenAmount) / Number(zeroPad(1, receiveCurrency.decimals))).toString() || "")
    //     setRate(Number(r.toTokenAmount) / Number(zeroPad(1, receiveCurrency.decimals)) / Number(sendInput))
    //   })
    // apiInch
    //   .quote(chainId || 1,{
    //     fromTokenAddress: sendCurrency.address,
    //     toTokenAddress: receiveCurrency.address,
    //     amount: Number(zeroPad(1, sendCurrency.decimals)) || 0,
    //   })
    //   .then(r => {
    //     setPricePerOneLeft(Number(r.toTokenAmount) / Number(zeroPad(1, receiveCurrency.decimals)) || 0)
    //   })
    //   .catch(() => {
    //     setPricePerOneLeft(0)
    //   })
    // apiInch
    //   .quote(chainId || 1,{
    //     fromTokenAddress: receiveCurrency.address,
    //     toTokenAddress: sendCurrency.address,
    //     amount: Number(zeroPad(1, receiveCurrency.decimals)) || 0,
    //   })
    //   .then(r => {
    //     setPricePerOneRight(Number(r.toTokenAmount) / Number(zeroPad(1, sendCurrency.decimals)) || 0)
    //   })
    //   .catch(() => {
    //     setPricePerOneRight(0)
    //   })

    setReceiveInput((stat.find(i => i.id === sendCurrency.id)?.current_price  * Number(sendInput) / stat.find(i => i.id === receiveCurrency.id)?.current_price).toString())

    if (sendInput === "0") {
      setReceiveInput("0")
    }
  }, [sendCurrency, receiveCurrency, sendInput, stat])

  async function onSwap() {
    const allowance = await axios.get(
      `https://api.1inch.io/v5.0/${chainId || 1}/approve/allowance?tokenAddress=${sendCurrency.address}&walletAddress=${address}`,
    )

    if (allowance.data.allowance === "0") {
      const approve = await axios.get(
        `https://api.1inch.io/v5.0/${chainId || 1}/approve/transaction?tokenAddress=${sendCurrency.address}`,
      )

      setTxDetails(approve.data)
      console.log("not approved")
      return
    }

    const tx = await axios.get(
      `https://api.1inch.io/v5.0/${chainId || 1}/swap?fromTokenAddress=${sendCurrency.address}&toTokenAddress=${
        receiveCurrency.address
      }&amount=${
        Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals))
      }&fromAddress=${address}&slippage=${slippage}`,
    )

    var decimals = Number(`1E${receiveCurrency.decimals}`)
    setReceiveInput((Number(tx.data.toTokenAmount) / decimals).toFixed(2))
    setTxDetails(tx.data.tx)
  }
  async function onLimit() {
    const allowance = await axios.get(
      `https://api.1inch.io/v5.0/${chainId || 1}/approve/allowance?tokenAddress=${sendCurrency.address}&walletAddress=${address}`,
    )

    if (allowance.data.allowance === "0") {
      const approve = await axios.get(
        `https://api.1inch.io/v5.0/${chainId || 1}/approve/transaction?tokenAddress=${sendCurrency.address}`,
      )

      setTxDetails(approve.data)
      console.log("not approved")
      return
    }

    // // @ts-ignore
    // const connector = new Web3ProviderConnector(new Web3(library.provider))
    // const contractAddress = limitOrderProtocolAddresses[chainId || 1]
    //
    // const limitOrderBuilder = new LimitOrderBuilder(contractAddress, chainId || 1, connector)
    //
    // const limitOrder = limitOrderBuilder.buildLimitOrder({
    //   makerAssetAddress: sendCurrency.address,
    //   takerAssetAddress: receiveCurrency.address,
    //   makerAddress: `${sendCurrency.address}`,
    //   makingAmount: (Number(sendInput) * Number(zeroPad(1, sendCurrency.decimals))).toString(),
    //   takingAmount: (Number(receiveInput) * Number(zeroPad(1, receiveCurrency.decimals))).toString(),
    // })
    //
    // const limitOrderTypedData = limitOrderBuilder.buildLimitOrderTypedData(limitOrder)
    // const limitOrderSignature = limitOrderBuilder.buildOrderSignature(`${address}`, limitOrderTypedData)
    // const limitOrderHash = limitOrderBuilder.buildLimitOrderHash(limitOrderTypedData)
    //
    // console.log(limitOrderHash)
  }


  const getLabelForRate = () => {
    if (isRateDefault) {
      return `Sell ${sendCurrency.token} at rate`
    }

    return `Bug ${receiveCurrency.token} at rate`
  }

  // const onSwitchRate = () => {
  //   if (isRateDefault) {
  //     setRate(Number(sendInput) / Number(receiveInput))
  //     setIsRateDefault(false)
  //   } else {
  //     setRate(Number(receiveInput) / Number(sendInput))
  //     setIsRateDefault(true)
  //   }
  // }

  const getWalletBalance = (token: string) => {
    if (token === "ETH") {
      return walletBalanceEth
    }
    if (token === "USDT") {
      return walletBalanceUsdt
    }
    if (token === "USDC") {
      return walletBalanceUsdc
    }
    if (token === "UNI") {
      return walletBalanceUni
    }
    if (token === "LINK") {
      return walletBalanceLink
    }
    if (token === "WBTC") {
      return walletBalanceWbtc
    }
    if (token === "APE") {
      return walletBalanceApe
    }
    if (token === "WETH") {
      return walletBalanceWeth
    }

    return 0
  }

  const onClickMax = () => {
    setSendInput(getWalletBalance(sendCurrency.token).toString())
  }

  useEffect(() => {
    if (debouncedRate) {
      if (!isRateDefault) {
        setReceiveInput((Number(sendInput) * Number(debouncedRate)).toString())
      } else {
        // setReceiveInput((Number(sendInput) * Number(debouncedRate)).toString())
      }
    }
  }, [debouncedRate])

  return (
    <div className="swap-other" style={{ backgroundImage: `url(${imgMainBgSrc})` }}>
      {/*<CancelModal onClose={() => navigate(-1)} header='The smart contract is maintained' />*/}
      {loading && <Loading />}
      <Header />
      <div className="swap-other-content">
        <h3 className="swap-other-content-title">Lightning cryptocurrency exchange</h3>
        <div className="swap-other-content-data">
          <div className="swap-other-content-data-tables">
            <div className="swap-other-content-block-tabs">
              <button
                type="button"
                onClick={() => setTabsChart("send")}
                className={cn("swap-other-content-block-tabs-item", {
                  active: tabsChart === "send",
                })}
              >
                {sendCurrency.name}
              </button>
              <button
                type="button"
                onClick={() => setTabsChart("receive")}
                className={cn("swap-other-content-block-tabs-item", {
                  active: tabsChart === "receive",
                })}
              >
                {receiveCurrency.name}
              </button>
            </div>

            {/*@ts-ignore*/}
            {stat1 !== undefined && tabsChart === "send" && (<Chart options={stat1.options} series={stat1.series} type="area"/>)}
            {/*@ts-ignore*/}
            {stat2 !== undefined && tabsChart === "receive" && (<Chart options={stat2.options} series={stat2.series} type="area"/>)}
          </div>
          {/*{*/}
          {/*  // @ts-ignore*/}
          {/*  (<stargate-widget theme="light"/>)*/}
          {/*}*/}
          <div className="swap-other-content-block">
            <div className="swap-other-content-block-tabs">
              <button
                type="button"
                onClick={() => setTabs("swap")}
                className={cn("swap-other-content-block-tabs-item", {
                  active: tabs === "swap",
                })}
              >
                Swap
              </button>
              <button
                type="button"
                onClick={() => setTabs("limit")}
                className={cn("swap-other-content-block-tabs-item", {
                  active: tabs === "limit",
                })}
              >
                Limit
              </button>
            </div>
            <div className="swap-other-content-block-first">
              <div className="swap-other-content-block-first-input">
                <Input
                  isError={Number(sendInput) > getWalletBalance(sendCurrency.token) && !!address}
                  className="swap-other-content-block-first-input-f"
                  onChange={v => setSendInput(v)}
                  value={sendInput}
                  label={
                    <div className="swap-other-content-block-first-label">
                      <div>You sell</div>
                      {address && (
                        <div className="swap-other-content-block-first-label-max">
                          Balance:{" "}
                          {getWalletBalance(sendCurrency.token) === 0
                            ? 0
                            : getWalletBalance(sendCurrency.token).toFixed(9)}{" "}
                          <button onClick={onClickMax} className="swap-other-content-block-first-label-max-btn">
                            MAX
                          </button>
                        </div>
                      )}
                    </div>
                  }
                  placeholder="1"
                  append={
                    <CoinsDropdown
                      chainId={chainId || 1}
                      isLimit={tabs === "limit"}
                      currentCoin={sendCurrency}
                      onSelect={newCoin => setSendCurrency(newCoin)}
                    />
                  }
                />
                <div className="swap-other-content-block-first-input-desc">
                  <div>
                    1 {sendCurrency.token} ≈ {moneyFormatter.format(stat.find(i => i.id === sendCurrency.id)?.current_price / stat.find(i => i.id === receiveCurrency.id)?.current_price)} {receiveCurrency.token}
                  </div>
                  <div>
                    {!!stat?.length
                      ? moneyFormatter.format(
                        stat?.find(iOther => iOther.symbol.toUpperCase() === sendCurrency.token)?.current_price *
                        Number(sendInput || 0),
                      )
                      : "Loading"}
                  </div>
                </div>
              </div>
              <button onClick={changeValues} className="swap-other-content-block-first-change">
                <Change/>
              </button>
              <div className="swap-other-content-block-first-input">
                <Input
                  className="swap-other-content-block-first-input-d"
                  onChange={v => setReceiveInput(v)}
                  value={receiveInput}
                  label="You buy"
                  append={
                    <CoinsDropdown
                      chainId={chainId || 1}
                      isLimit={tabs === "limit"}
                      currentCoin={receiveCurrency}
                      onSelect={newCoin => setReceiveCurrency(newCoin)}
                    />
                  }
                />
                <div className="swap-other-content-block-first-input-desc">
                  <div>
                    1 {receiveCurrency.token} ≈ {stat.find(i => i.id === receiveCurrency.id)?.current_price / stat.find(i => i.id === sendCurrency.id)?.current_price} {sendCurrency.token}
                  </div>
                  <div>
                    {!!stat?.length
                      ? moneyFormatter.format(
                        stat?.find(iOther => iOther.symbol.toUpperCase() === receiveCurrency.token)?.current_price *
                        Number(receiveInput || 0),
                      )
                      : "Loading"}
                  </div>
                </div>
              </div>
            </div>
            {tabs === "limit" && (
              <div className="swap-other-content-block-limit">
                <Input
                  onChange={v => setRate(v)}
                  value={rate}
                  label={getLabelForRate()}
                  //        append={(
                  //   <button onClick={onSwitchRate} type='button' className='swap-other-content-block-limit-switch'>
                  //     {isRateDefault ? sendCurrency.token : receiveCurrency.token} <Switch />
                  //   </button>
                  // )}
                />
                {/*<ExpiresDropdown activeValue={expires} setActiveValue={v => setExpires(v)} />*/}
              </div>
            )}
            {tabs === "swap" ? (
              <>
                {!address && (
                  <ConnectKitButton />
                )}
                {address && (
                  <>
                    {Number(sendInput) <= getWalletBalance(sendCurrency.token) ? (
                      <SimpleButton
                        className="swap-other-content-block-btn"
                        onClick={onSwap}
                        text="Exchange Now"
                        variant="colored"
                      />
                    ) : (
                      <SimpleButton
                        className="swap-other-content-block-btn"
                        text="You don't have enough money"
                        variant="yellow"
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {!address && (
                  <ConnectKitButton />
                )}
                {address && (
                  <>
                    {Number(sendInput) <= getWalletBalance(sendCurrency.token) ? (
                      <SimpleButton
                        className="swap-other-content-block-btn"
                        onClick={onLimit}
                        text="Limit"
                        variant="colored"
                      />
                    ) : (
                      <SimpleButton
                        className="swap-other-content-block-btn"
                        text="You don't have enough money"
                        variant="yellow"
                      />
                    )}
                  </>
                )}
              </>
            )}
            <p className="swap-other-content-block-desc">
              By using the site and creating an exchange, you agree to the Fixed InfinityStakeChain&apos;s{" "}
              <Link to={`${routes.terms}?${search.toString()}`}>Terms of Services</Link> and{" "}
              <Link to={`${routes.terms}?${search.toString()}`}>Privacy Policy</Link>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
