import img11 from "assets/icons/pool/bnb.png"
import img12 from "assets/icons/pool/busd.webp"
import img13 from "assets/icons/pool/cake.png"
import img15 from "assets/icons/pool/baby-doge.png"
import img16 from "assets/icons/pool/trust-wallet.png"
import img17 from "assets/icons/pool/gmt.webp"
import img18 from "assets/icons/pool/c98.png"

export type ICoin = {
  icon: string
  name: string
  token: string
  address: string
  id: string
  decimals: number
}

export const coinsBsc: ICoin[] = [
  {
    icon: img11,
    name: "Binance",
    token: "BNB",
    address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    decimals: 18,
    id: "binancecoin",
  },
  {
    icon: img11,
    name: "Wrapped Bitcoin",
    token: "WBNB",
    address: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    decimals: 18,
    id: "wrapped-bitcoin",
  },
  {
    icon: img12,
    name: "Binance USD",
    token: "BUSD",
    address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    decimals: 18,
    id: "binance-usd",
  },
  {
    icon: img13,
    name: "PancakeSwap",
    token: "CAKE",
    address: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    decimals: 18,
    id: "pancakeswap-token",
  },
  {
    icon: img15,
    name: "Baby Doge Coin",
    token: "BABYDOGE",
    address: "0xc748673057861a797275cd8a068abb95a902e8de",
    decimals: 9,
    id: "baby-doge-coin",
  },
  {
    icon: img16,
    name: "Trust Wallet",
    token: "TWT",
    address: "0x4b0f1812e5df2a09796481ff14017e6005508003",
    decimals: 18,
    id: "trust-wallet-token",
  },
  {
    icon: img17,
    name: "STEPN",
    token: "GMT",
    address: "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
    decimals: 8,
    id: "stepn",
  },
  {
    icon: img18,
    name: "Coin98",
    token: "C98",
    address: "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
    decimals: 18,
    id: "coin98",
  },
]

export const coinsBscLimit: ICoin[] = [
  {
    icon: img11,
    name: "Wrapped Bitcoin",
    token: "WBNB",
    address: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    decimals: 18,
    id: "wrapped-bitcoin",
  },
  {
    icon: img12,
    name: "Binance USD",
    token: "BUSD",
    address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    decimals: 18,
    id: "binance-usd",
  },
  {
    icon: img13,
    name: "PancakeSwap",
    token: "CAKE",
    address: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    decimals: 18,
    id: "pancakeswap-token",
  },
  {
    icon: img15,
    name: "Baby Doge Coin",
    token: "BABYDOGE",
    address: "0xc748673057861a797275cd8a068abb95a902e8de",
    decimals: 9,
    id: "baby-doge-coin",
  },
  {
    icon: img16,
    name: "Trust Wallet",
    token: "TWT",
    address: "0x4b0f1812e5df2a09796481ff14017e6005508003",
    decimals: 18,
    id: "trust-wallet-token",
  },
  {
    icon: img17,
    name: "STEPN",
    token: "GMT",
    address: "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
    decimals: 8,
    id: "stepn",
  },
  {
    icon: img18,
    name: "Coin98",
    token: "C98",
    address: "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
    decimals: 18,
    id: "coin98",
  },
]