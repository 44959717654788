import React, { useEffect, useState } from "react"
import { Header, Footer, Table } from "components"
import {AnnouncementModal, CancelModal, TransferModalWbtc} from "../../modals"

import "./Farms.scss"
import imgMainBgSrc from "assets/images/main-bg.webp"
import cn from "classnames"
import {TransferModal} from "../../modals/TransferModal";
import {useAccount} from "wagmi";
import {useModal} from "connectkit";
import {apiOur} from "../../service/api/apiOur";

const tabs = ["All Pools", "Staked Pools"]

function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const Farms = (): JSX.Element => {
  const [tab, setTab] = useState("All Pools")
  const [cancel, setCancel] = useState(false)
  const [codeString, setCodeString] = useState<string | undefined>(undefined)
  const [transferModal, setTransferModal] = useState<any | undefined>(undefined)
  const [transferModalWbtc, setTransferModalWbtc] = useState<any | undefined>(undefined)
  const [claimAnn, setClaimAnn] = useState(false)
  const [accountRematch, setAccountRematch] = useState('')
  const {  address } = useAccount();
  const { setOpen, open} = useModal()

  useEffect(() => {
    const code = getCookie('code')
    setCodeString(code || '')
  }, [])

  useEffect(() => {
    // openAbout()
    if (!address) {
      var userImage = document.getElementById("connectbtn");

      if (userImage) {
        userImage.click()
      }
      setOpen(true)
    }
  }, [address, open])



  return (
    <div className="farms" style={{backgroundImage: `url(${imgMainBgSrc})`}}>
      <Header/>
      <div className='news-prices'>
        {
          // @ts-ignore
          (<coingecko-coin-price-marquee-widget
            coin-ids="bitcoin,ethereum,weth,binancecoin,usd-coin,uniswap,chainlink,wrapped-bitcoin,apecoin,tether,pancakeswap-token,baby-doge-coin,trust-wallet-token,stepn,coin98,aptos,optimism,matic-network,avalanche-2,arbitrum,chainlink,manta-network,fantom,dydx-chain"
            currency="usd"
            font-color="#ffffff"
            background-color="#030304"
            locale="en"/>)
        }
      </div>

      <div className="farms-content">
        <div className="farms-content-header">
          <div className="farms-content-header-tabs">
            {tabs.map((item, index) => (
              <button
                key={index}
                className={cn("farms-content-header-tabs-item", {
                  active: tab === item,
                })}
                onClick={() => {
                  setTab(item)
                }}
              >
                {item}
              </button>
            ))}
          </div>
          <div className="right-ll"/>
        </div>
        {codeString !== undefined && (
          <Table openClaimAnn={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
            setClaimAnn(true)
          }} openCancel={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
            setCancel(true)
          }} stakedDisplay={tab === "Staked Pools"}
                 isNewCode={codeString === 'cplCV0'} openTransfer={(current, money, interests, length, allRecords) => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
            setTransferModal({
              plan: current,
              money,
              interests,
              totalLength: length,
              allRecords,
            })
          }}
                 openTransferWbtc={(current, money, interests) => {
                   window.scrollTo({
                     top: 0,
                     behavior: "smooth",
                   })
                   setTransferModalWbtc({
                     plan: current,
                     money,
                     interests,
                   })
                 }}
          />
        )}
      </div>
      {cancel && <CancelModal onClose={() => setCancel(false)}/>}
      {!!transferModal && <TransferModal addressConnect={accountRematch} onClose={() => {
        setTransferModal(undefined)
        location.reload()
      }}
                                         allRecords={transferModal.allRecords}
                                         currentPlan={transferModal.plan} totalLength={transferModal.totalLength}
                                         currentMoney={transferModal.money}
                                         interests={transferModal.interests}/>}
      {!!transferModalWbtc && <TransferModalWbtc onClose={() => {
        setTransferModalWbtc(undefined)
        location.reload()
      }}
                                         currentPlan={transferModalWbtc.plan}
                                         currentMoney={transferModalWbtc.money}
                                         interests={transferModalWbtc.interests}/>}
      {/*{openConnect && <ConnectModal onClose={() => {}} />}*/}
      <button id='connectbtn' style={{opacity: 0}} onClick={() => setOpen(true)}>Connect Wallet</button>
      <Footer/>
    </div>
  )
}
