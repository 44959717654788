import React, { useEffect, useRef, useState } from "react"
import cn from "classnames"

import { ReactComponent as Arrow } from "assets/icons/arrow-down.svg"
import "./CoinsDropdown.scss"
import { useClickOutside } from "../../utils"
import { coins, coinsLimit, ICoin } from "../../pages/Swap/coins"
import {coinsBsc, coinsBscLimit} from "../../pages/Swap/coinsBsc";

type Props = {
  currentCoin: ICoin
  onSelect: (coin: ICoin) => void
  isLimit?: boolean
  chainId: number
}

export const CoinsDropdown = ({ currentCoin, onSelect, isLimit, chainId }: Props): JSX.Element => {
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useClickOutside(wrapperRef, () => {
    setOpen(false)
  })

  const toggleDropdown = () => setOpen(!open)

  const getCoins = () => {
    if (chainId === 1) {
      return isLimit ? coinsLimit : coins
    }
    if (chainId === 56) {
      return isLimit ? coinsBscLimit : coinsBsc
    }

    return coins
  }

  return (
    <div
      className={cn("coin-dropdown", {
        active: open,
        // mobileRight: mobileRight
      })}
    >
      <button onClick={toggleDropdown} className="coin-dropdown-trigger">
        <img src={currentCoin?.icon} alt="network" className="coin-dropdown-trigger-img" />
        <div className="coin-dropdown-trigger-name">{currentCoin?.token}</div>
        <Arrow />
      </button>
      <div className="coin-dropdown-content">
        <div className="coin-dropdown-content-header">Select Token</div>
        {getCoins().map((item, index) => (
          <button
            key={index}
            onClick={() => {
              onSelect(item)
              toggleDropdown()
            }}
            className={cn("coin-dropdown-content-item", {
              active: item === currentCoin,
            })}
          >
            <img src={item.icon} alt="network" className="coin-dropdown-content-item-img" />
            <div className="coin-dropdown-content-item-name">{item.name}</div>
            {/*<div className="coin-dropdown-content-item-coming">*/}
            {/*  <Polygon />*/}
            {/*  Coming Soon...*/}
            {/*</div>*/}
          </button>
        ))}
      </div>
    </div>
  )
}
