import React, {useEffect} from "react"

import img1 from "assets/images/ipfs.png"
import img2 from "assets/icons/pool/link.svg"
import img3 from "assets/icons/lock.svg"
import "./Ecosystem.scss"

export const Ecosystem = (): JSX.Element => {
  const blocks = [
    {
      title: "IPFS Integration",
      desc: "By utilizing the InterPlanetary File System (IPFS), VaultEscrow ensures a decentralized and resilient storage solution for important data, enhancing the overall stability and reliability of our platform.",
      icon: img1,
    },
    {
      title: "Chainlink Partnership",
      desc: "Our collaboration with Chainlink provides secure and accurate price feeds through decentralized oracle networks, ensuring the integrity of our staking platform and safeguarding users' assets.",
      icon: img2,
    },
    {
      title: "Regular Security Audits",
      desc: "To maintain the highest security standards, VaultEscrow undergoes routine security audits conducted by industry-leading experts. These audits help us identify and address potential vulnerabilities, ensuring the ongoing safety and reliability of our platform.",
      icon: img3,
    },
  ]

  // useEffect(() => {
  //   const box = document.querySelector(".ecosystem-content-item");
  //   const boxPosition = box?.getBoundingClientRect()
  //
  //   const moveShadow = (e) => {
  //     const shadow = document.createElement("div");
  //     shadow.classList.add("cursor-shadow");
  //     box?.append(shadow);
  //
  //     // @ts-ignore
  //     shadow.style.top = e.clientY - boxPosition.y +'px'
  //     // @ts-ignore
  //     shadow.style.left = e.clientX - boxPosition.x +'px'
  //
  //     setTimeout(() => {
  //       box?.removeChild(shadow)
  //     }, 1000)
  //   };
  //
  //   box?.addEventListener("mousemove", (e) => moveShadow(e));
  // }, [])

  return (
    <div className="ecosystem">
      <h3 data-aos="fade-up" className="ecosystem-title">VaultEscrow: A Reliable Platform You Can Trust</h3>
      <p data-aos="fade-up" className="ecosystem-desc">
        At VaultEscrow, we understand the importance of trust and reliability in the crypto space.
      </p>
      <div data-aos="zoom-in-down" className="ecosystem-content">
        {blocks.map((block, index) => (
          <div key={index} className="ecosystem-content-item">
            <div className="ecosystem-content-item-icon">
              <img alt="ecosystem" src={block.icon}/>
            </div>
            <div className="ecosystem-content-item-title">{block.title}</div>
            <div className="ecosystem-content-item-desc">{block.desc}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
