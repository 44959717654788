import React, { useState } from "react"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import { Bar } from "react-chartjs-2"

import "./TradeChart.scss"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const options = {
  responsive: true,
  interaction: {
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          return tooltipItem.raw.toFixed(7)
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      suggestedMin: 100000, // minimum will be 0, unless there is a lower value.
      beginAtZero: false, // minimum value will be 0.
    },
  },
}

type Props = {
  data: any
  name: string
}

export const TradeChart = ({ data, name }: Props): JSX.Element => {
  return (
    <div className="trade-chart">
      <div className="trade-chart-content">
        <div className="trade-chart-content-subtitle">{name}</div>
        <Bar options={options} data={data} />
      </div>
    </div>
  )
}
