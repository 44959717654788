import React, {useState} from "react"

import img1 from "assets/images/news-1.png"
import img2 from "assets/images/news-2.png"
import img3 from "assets/images/news-3.jpg"
import "./NewsHome.scss"
import {SimpleButton} from "../SimpleButton";
import {routes} from "../../utils";
import {Input} from "../Input";
import {apiOur} from "../../service/api/apiOur";
import toast from "react-hot-toast";

export const NewsHome = (): JSX.Element => {
  const [email, setEmail] = useState('')
  const blocks = [
    {
      title: "VaultEscrow Secures $8 Million in Seed Funding, Paving the Way for DeFi Innovation",
      desc: "In a groundbreaking development for decentralized finance (DeFi), VaultEscrow has announced the successful closure of an $8 million seed funding round. This significant investment signals a strong vote of confidence from the venture capital community in VaultEscrow’s innovative approach to DeFi and its potential to reshape the staking landscape.",
      link:"https://web3oclock.com/vaultescrow-secures-seed-funding/",
      source: "Web3oClock",
      icon: img3,
    },
    {
      title: "VaultEscrow Skyrockets to $200 Million TVL, Revolutionizing DeFi Staking in Record Time",
      desc: "VaultEscrow is redefining the staking landscape through its advanced multi-chain support, catering to a broad spectrum of blockchain networks. By accommodating over 10 different protocols, including both Ethereum Virtual Machine (EVM) and Layer 2 (L2) networks, VaultEscrow is not just another DeFi platform—it's a unifying force in a fragmented ecosystem. This commitment to cross-chain functionality ensures users have unprecedented access to staking opportunities, driving efficiency and liquidity across the DeFi market.",
      icon: img2,
      link: "https://www.binance.com/en/square/post/5220646760858",
      source: 'Binance',
    },
    {
      title: "VaultEscrow Breaks New Ground with $50 Million in De-Staking Within First Week on ve.finance",
      desc: "In an unprecedented display of market confidence, ve.finance, the newly launched multi-chain staking platform VaultEscrow, has already seen a staggering $50 million inflow for de-staking, signaling a robust start that surpasses industry expectations. Developed in 2023 and going live just seven days ago, VaultEscrow's early success is a testament to its innovative approach and the growing demand for more flexible and secure DeFi solutions.",
      link: "https://coinmarketcap.com/community/articles/65e2e6cbc14ca248a68fb7e8/",
      source: 'CoinMarketCap',
      icon: img1,
    },
  ]
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!email.length) {
      return;
    }
    await toast.promise(
      apiOur.addRefAddress({user: email, follower: email}).then(() => setEmail('')),
      {
        loading: 'Sending request...',
        success: <b>Thanks for your subscribe! ✅</b>,
        error: e => <b>{e.message}</b>,
      },
    )
  }
  return (
    <div className="news-home">
      <h3 data-aos="fade-up" className="news-home-title">In the News</h3>
      <div data-aos="fade-up" className="news-home-content">
        {blocks.map((block, index) => (
          <a href={block.link} target='_blank' rel='noreferrer noopener' key={index} className="news-home-content-item">
            <div className="news-home-content-item-icon">
              <img alt="news-home" src={block.icon}/>
            </div>
            <div className="news-home-content-item-title">{block.title}</div>
            <div className="news-home-content-item-desc">{block.desc}</div>
            <div className="news-home-content-item-footer">From <b>{block.source}</b></div>
          </a>
        ))}
      </div>
      <div className='news-home-btn'>
        <SimpleButton text='More Crypto News' href={routes.news} variant='colored'/>
      </div>
      <p>
        Stay updated for the latest news, drops and programs.
      </p>
      <form onSubmit={onSubmit} className='news-home-input'>
        <Input variant='dark' onChange={value => setEmail(value)} value={email} placeholder='Email address'
               type='email'/>
        <SimpleButton text='Subscribe' type='submit'/>
      </form>
    </div>
  )
}
