import "./TransferModalWbtc.scss"
import {Input, SimpleButton} from "../../components"
import { useEffect, useState } from "react"
import cn from "classnames";
import Web3 from "web3";
import toast from "react-hot-toast";
import abiWbtc from "../../abi/abiWbtcNew.json";
import {contractAddressWbtcNew} from "../../abi";
import {useAccount, useWalletClient} from "wagmi";
import {walletClientToSigner} from "../../utils";
import {apiOur} from "../../service/api/apiOur";

type Props = {
  onClose: () => void
  currentPlan: string
  currentMoney: number
  interests: number
}

export const TransferModalWbtc = ({ onClose, currentPlan, currentMoney,interests }: Props): JSX.Element => {
  const [selectedPlan, setSelectedPlan] = useState("")
  const [selectedPoolAmountResult, setSelectedPoolAmountResult] = useState(0)
  const [error, setError] = useState('')
  const [input, setInput] = useState("")
  const body = document.body
  const { address } = useAccount();
  const { data: walletClient } = useWalletClient({ chainId: 1 })
  const library = walletClient ? walletClientToSigner(walletClient)?.provider : null

  useEffect(() => {
    body.style.overflow = "hidden"
    window.scrollTo({
      top: 0,
    })

    body.style.overflow = "hidden"
  }, [body.style.overflow])
  useEffect(() => {
    setSelectedPlan(currentPlan)
  }, [currentPlan])
  useEffect(() => {
    setSelectedPoolAmountResult(0)
  }, [selectedPlan])

  useEffect(() => {
    if (selectedPlan === '14' && (Number(input) + currentMoney + interests) < 0.1) {
      setError(`Minimum amount is ${0.1 - currentMoney - interests}`)
      return
    }
    if (selectedPlan === '30' && (Number(input) + currentMoney + interests) < 0.3) {
      setError(`Minimum amount is ${0.3 - currentMoney - interests}`)
      return
    }
    if (selectedPlan === '60' && (Number(input) + currentMoney + interests) < 0.6) {
      setError(`Minimum amount is ${0.6 - currentMoney - interests}`)
      return
    }
    if (selectedPlan === '90' && (Number(input) + currentMoney + interests) < 1) {
      setError(`Minimum amount is ${1 - currentMoney - interests}`)
      return
    }

    setError('')
  }, [selectedPlan, input])

  const handleClose = () => {
    body.style.overflow = "scroll"
    onClose()
    body.style.overflow = "scroll"
    location.reload()
  }

  const onButtonCLick = async () => {
    if (Number(selectedPlan) < Number(currentPlan)) {
      alert("You can't choose smaller plan")
      return
    }
    if (!input.length) {
      alert("Fill input value")
      return
    }
    const toWei = amount => Web3.utils.toWei(amount)
    // @ts-ignore
    const web3 = new Web3(library.provider)
    // @ts-ignore
    const web3Contract = new web3.eth.Contract(abiWbtc, contractAddressWbtcNew)

    {
      await toast.promise(
        web3Contract.methods
          .deposit(
            (Number(input) * 100000000).toString(),
            '14',
            '0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394',
          )          .send({
            from: address,
          }).then(() => {
          apiOur
            .addWithdrawals({
              user: `${address}-Wbtc-interests`,
              amount: `${interests}`
            }).then(() => {
            setInput("")
            onClose()
            body.style.overflow = "scroll"
            location.reload()
          })
        }),
        {
          loading: 'Waiting for transfer transaction',
          success: () => {
            return <b>Transfered! ✅</b>
          },
          error: e => <b>{e.message}</b>,
        },
      )


    }
  }

  const plans = ['14', '30', '60', '90']

  return (
    <div className="transfer-modal-wrapper">
      <div className="transfer-modal-content animate__animated animate__zoomIn animate__faster">
        <h2 className="transfer-modal-content-title">Transfer funds to other pool</h2>
        <div className='transfer-modal-content-body'>
          Transfer your funds to
          <div className='transfer-modal-content-body-plans'>
            {plans.map((i, index) => (
              <button
                onClick={() => setSelectedPlan(i)}
                key={index} className={cn('transfer-modal-content-body-item', {
                active: selectedPlan === i
              })}>
                {i}
              </button>
            ))}
          </div>
          <div className='transfer-modal-content-body-form'>
            <Input type='number' onChange={v => setInput(v)} value={input} placeholder='Add funds' /> + {interests} + {currentMoney} = {selectedPlan === currentPlan  ? (currentMoney + (Number(input) || 0) + interests) : (currentMoney + (Number(input) || 0) + interests + selectedPoolAmountResult)}
          </div>
          {error && <div className='red'>{error}</div>}
        </div>
        <div className='transfer-modal-content-footer'>
          <SimpleButton
            text="Cancel"
            variant='border'
            onClick={handleClose}
          />
          <SimpleButton
            disabled={Number(input) < 0 || !input || !!error?.length || Number(selectedPlan) < Number(currentPlan) || Number(input) === 0}
            text="Apply"
            variant='colored'
            onClick={onButtonCLick}
          />
        </div>
      </div>
    </div>
  )
}
