import React from "react"

import img1 from "assets/icons/partners/1.svg"
import img2 from "assets/icons/partners/2.svg"
import img3 from "assets/icons/partners/3.svg"
import img4 from "assets/icons/partners/4.svg"
import img5 from "assets/icons/partners/5.svg"
import img6 from "assets/icons/partners/6.svg"
import img7 from "assets/icons/partners/7.svg"
import img9 from "assets/icons/partners/9.svg"
import img10 from "assets/icons/partners/10.svg"
import img11 from "assets/icons/partners/11.svg"
import img12 from "assets/icons/partners/12.svg"
import img13 from "assets/icons/partners/13.svg"
import img14 from "assets/icons/partners/14.svg"
import img15 from "assets/icons/partners/15.svg"
import "./Partners.scss"
import {InfinityScroll} from "../InfinityScroll";

export const Partners = (): JSX.Element => {
  const partners = [
    {
      link: img1,
      width: 241,
      height: 60,
    },
    {
      link: img2,
      width: 215,
      height: 60,

    },
    {
      link: img3,
      width: 280,
      height: 60,

    },
    {
      link: img4,
      width: 170,
      height: 60,

    },
    {
      link: img5,
      width: 200,
      height: 60,

    },
    {
      link: img6,
      width: 187,
      height: 60,

    },
    {
      link: img7,
      width: 198,
      height: 60,

    },
    {
      link: img9,
      width: 220,
      height: 60,
    },
    {
      link: img10,
      width: 210,
      height: 60,
    },
    {
      link: img11,
      width: 240,
      height: 60,
    },
    {
      link: img12,
      width: 210,
      height: 60,
    },
    {
      link: img13,
      width: 210,
      height: 60,
    },
    {
      link: img14,
      width: 300,
      height: 60,
    },
    {
      link: img15,
      width: 230,
      height: 60,
    },
  ]
  return (
    <div className="partners">
      <h3 data-aos="fade-up" className="partners-title">Our Partners</h3>
      <p data-aos="fade-up" className="partners-desc">
        Partners from various industries trust us and help us to create a reliable ecosystem that benefits both
        individuals and businesses.
      </p>
      <div data-aos="fade-up-left" className="partners-content">
        <InfinityScroll items={partners}/>
      </div>
    </div>
  )
}
