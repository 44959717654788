import img1 from "assets/icons/pool/bnb.png"
import img2 from "assets/icons/pool/busd.webp"
import img3 from "assets/icons/pool/cake.png"
import img5 from "assets/icons/pool/baby-doge.png"
import img6 from "assets/icons/pool/trust-wallet.png"
import img7 from "assets/icons/pool/gmt.webp"
import img8 from "assets/icons/pool/c98.png"

export const poolDataBsc = [
  {
    icon: img1,
    name: "Binance",
    token: "BNB",
    id: "binancecoin",
    network: "BNB Chain",
    addresses: [
      "0x7694399cba11469fdbba450917f0dec53b1fdf44",
      "0x694446a2647e384fd33a3b6e513a6475f77379af",
      "0x7f08f173fe84b0774e3648531162105c9f0e7497",
      "0xaa5bc284e38d1eed890d6de8fdba10c30967ecd1",
      "0x536d770b8c5e49fb3d4845afc2ad3aa752b01b62",
    ],
    quantity: ["1-10", "10-30", "25-50", "40-100", "50-200"],
    percents: ["0.2", "1.5", "4", "9", "15"],
    days: ["3", "14", "30", "60", "90"],
  },
  {
    icon: img2,
    name: "Binance USD",
    token: "BUSD",
    id: "binance-usd",
    network: "BNB Chain",
    addresses: [
      "0x0f0231b43ee64d53dcae20b89783ee78a48bbc24",
      "0x1ca2bc2e401eae4320c17528b91b078b3d16d39d",
      "0x9005f21e81ff456b374cd1e40e6237d67dc687b3",
      "0xa82bfe1f2981cce300ae47b05b24e22ad7be2598",
      "0x444ed79c5f80676ae9d110952a8563483df802a9",
    ],
    addAddress: [
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
      "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    ],
    quantity: ["500-5000", "5000-10000", "10000-30000", "20000-50000", "30000-100000"],
    percents: ["0.15", "0.8", "2", "5", "8.5"],
    days: ["3", "14", "30", "60", "90"],
  },
  {
    icon: img3,
    name: "PancakeSwap",
    id: "pancakeswap-token",
    network: "BNB Chain",
    token: "CAKE",
    addresses: [
      "0x309e3bba0e2b16303ad6288e99aed3dd36a54220",
      "0xd7ec6481a8722c9a06a6da1b2d024f4aa46d6a95",
      "0x46533f26eb4080e2050e3f8a3014aedf7b5fdb12",
      "0x7accc054bb199ca976c95aee495c9888f566aaaa",
      "0x91b3927f100bb6c19e5434bfaba07d60670b98d6",
    ],
    addAddress: [
      "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
      "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
      "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
      "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
      "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    ],
    quantity: ["300-3000", "3000-10000", "10000-30000", "20000-50000", "30000-100000"],
    percents: ["0.8", "4", "10", "22", "36"],
    days: ["3", "14", "30", "60", "90"],
  },
  {
    icon: img5,
    name: "Baby Doge Coin",
    token: "BABYDOGE",
    id: "baby-doge-coin",
    network: "BNB Chain",
    addresses: [
      "0x979f40ba17458ce7c3c756efd2dcc529180821e4",
      "0x97219afd7f2a54c82601567305ed54f6eac7d41f",
      "0x6a116b531eb60d4f5e2ab31fbcaec5c962865f87",
      "0xe356fe28b7b6b015a3b2bb4419dbdf2777d7420b",
      "0x8e9e89c1c4807b7059436e468bb0082f76e6d02f",
    ],
    addAddress: [
      "0xc748673057861a797275cd8a068abb95a902e8de",
      "0xc748673057861a797275cd8a068abb95a902e8de",
      "0xc748673057861a797275cd8a068abb95a902e8de",
      "0xc748673057861a797275cd8a068abb95a902e8de",
      "0xc748673057861a797275cd8a068abb95a902e8de",
    ],
    quantity: ["100-1000", "500-2000", "1500-5000", "3000-10000", "5000-20000"],
    percents: ["0.3", "1.4", "3", "7", "11"],
    days: ["3", "14", "30", "60", "90"],
  },
  {
    icon: img6,
    name: "Trust Wallet",
    token: "TWT",
    network: "BNB Chain",
    id: "trust-wallet-token",
    addresses: [
      "0x00c44e80e4bb80c175a38afde0150adbaecff303",
      "0xa1f9ae3b751e20fb0a02ee64b42dff537662a9c0",
      "0x5142609a89b454bc9e1aac2dc0d0b2aaa7b20b2b",
      "0x4d4d83270f2fa868f89d8a69345b684808c97fa9",
      "0xae1314d6fbd2de2c26a7164ddd1fede5544b06cc",
    ],
    addAddress: [
      "0x4b0f1812e5df2a09796481ff14017e6005508003",
      "0x4b0f1812e5df2a09796481ff14017e6005508003",
      "0x4b0f1812e5df2a09796481ff14017e6005508003",
      "0x4b0f1812e5df2a09796481ff14017e6005508003",
      "0x4b0f1812e5df2a09796481ff14017e6005508003",
    ],
    quantity: ["500-5000", "5000-10000", "10000-30000", "20000-50000", "30000-100000"],
    percents: ["0.3", "1.4", "3", "7", "11"],
    days: ["3", "14", "30", "60", "90"],
  },
  {
    icon: img7,
    name: "STEPN",
    token: "GMT",
    id: "stepn",
    network: "BNB Chain",
    addresses: [
      "0xb99b2ad71489d282871df3ca731d7a1479ab58d6",
      "0xb7d0749a64345552ef01ff54d6864202215a09a1",
      "0x9c2c266620fed733dffcbef6121597b46b4f55ca",
      "0xe4d3df079fbef6529c893ee4e9298711d480ff35",
      "0xd6216fc19db775df9774a6e33526131da7d19a2c",
    ],
    addAddress: [
      "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
      "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
      "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
      "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
      "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
    ],
    quantity: ["2500-10000", "5000-30000", "50000-100000", "75000-150000", "100000-300000"],
    percents: ["0.3", "2", "5", "11", "18"],
    days: ["3", "14", "30", "60", "90"],
  },
  {
    icon: img8,
    name: "Coin98",
    network: "BNB Chain",
    token: "C98",
    id: "coin98",
    addresses: [
      "0x2f22bd3dff4bed265c49e140752607be80de11bc",
      "0x759ab0d3b8b2e681d1d77e63d61f0d351930063c",
      "0x80076a3ecaa5ec81335b208805b7371074504965",
      "0xdeef61bed60c31aa156da22938c66fef9af8bec1",
      "0x52a258ed593c793251a89bfd36cae158ee9fc4f8",
    ],
    addAddress: [
      "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
      "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
      "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
      "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
      "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
    ],
    quantity: ["2500-10000", "5000-30000", "50000-100000", "75000-150000", "100000-300000"],
    percents: ["0.2", "1.5", "4", "9", "15"],
    days: ["3", "14", "30", "60", "90"],
  },
]
