import React from 'react';
import ReactDOM from 'react-dom/client';
import { WagmiProvider, createConfig, http } from "wagmi";
import { mainnet, bsc } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import App from "./App";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


export const config = createConfig(
  // @ts-ignore
  getDefaultConfig({
    // Your dApps chains
    chains: [mainnet, bsc],
    transports: {
      // RPC URL for each chain
      [mainnet.id]: http(
        `https://eth-mainnet.g.alchemy.com/v2/pfUvtn6vEswkoladO5dOvtU54uI-8GjC`,
      ),
      // RPC URL for each chain
      [bsc.id]: http(),
    },
    // @ts-ignore
    options: {
      shimDisconnect: true,
    },
    // Required API Keys
    walletConnectProjectId: '2314a9fff6c75eac52130babbd0400b6',
  }),
);

const queryClient = new QueryClient();

AOS.init();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <ConnectKitProvider customTheme={{
          "--ck-connectbutton-font-size": "14px",
          "--ck-font-family": "'Poppins', Arial ,sans-serif",
          "--ck-connectbutton-font-weight": 600,
          "--ck-connectbutton-background": '#fff',
          "--ck-connectbutton-hover-background": '#fff',
          "--ck-connectbutton-hover-box-shadow": 'none',
          "--ck-connectbutton-box-shadow":'none',
          "--ck-connectbutton-border-radius":'14px',
          "--ck-connectbutton-color":'#060707',

        }}>
          <App />
        </ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);
