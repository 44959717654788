export const contractAddress = "0x800cfd4a2ba8ce93ea2cc814fce26c3635169017"
export const contractAddressUsdtNew = "0x7667562ba0dcc93d0cdaf37fd411276ec0db8735"
export const contractAddressBnb = "0xe20f8ccd8693340ec866066c2a2e15b20caefb55"
export const contractAddressBusd = "0xa334febed088fe12894a9c714740265373184272"
export const contractAddressUsdtSecond = "0x21f07aab59e4b46668bfa6706ca0aa74078121d6"
export const contractAddressUsdc = "0xaf3018f8040fc9c4dc7f9e41777989c21146273d"
export const contractAddressUsdcApprove = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
export const contractAddressLink = "0xe943e29480005d59e6b8b6b7fc4aad041c25d86c"
export const contractAddressLinkApprove = "0x514910771af9ca656af840dff83e8264ecf986ca"
export const contractAddressWbtc = "0xe2c6dd251e54b0022c4e4ed59e12a8b1f37b3fe5"
export const contractAddressWbtcNew = "0x8eaff6cb859f10aa7d48d1152dc7db4ceb8ebcba"
export const contractAddressWbtc2 = "0x0014b02e93556c8fa29eb4abfc0267b90de94fa6"
export const contractAddressWbtcApprove = "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599"
export const contractAddressUni = "0x62285a371e82d94adfcb7d11c54c7a6ba97e6618"
export const contractAddressUniApprove = "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984"
export const contractAddressApe = "0xab2459cc188745a7b789b070f8c6a1c7de404dfb"
export const contractAddressApeApprove = "0x4d224452801aced8b2f0aebe155379bb5d594381"
export const contractAddressEth = "0x625e538615be2f052179ef63456a32fbcaee944e"
export const contractAddressEthOld = "0xDaef06A5FBF22Cc67E521F937ab2A8e687558D74"
export const contractAddressWethApprove = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
export const contractAddressBusdApprove = "0xe9e7cea3dedca5984780bafc599bd69add087d56"
export const contractAddressCakeApprove = "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82"
export const contractAddressBabyDogeApprove = "0xc748673057861a797275cd8a068abb95a902e8de"
export const contractAddressTwtApprove = "0x4b0f1812e5df2a09796481ff14017e6005508003"
export const contractAddressGmtApprove = "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1"
export const contractAddressC98Approve = "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6"
export const approveAddress = "0xdac17f958d2ee523a2206206994597c13d831ec7"
export const refundAddress = "0x758E4002D69ddB4d170Db414Ce311E0Fcbdf312c"
export const retuenAddress = "0xD9b9Ea8d3385029C9870BF4611c09C24Ee0A54b2"
export const retuen2Address = "0x78588d491555b590031f7f6e05d7b9c60338e7e5"
export const retuen3Address = "0x424e3e23c5b8fc571b237863edfd3626ffead7c8"
