import img1 from "assets/icons/pool/eth.svg"
import img2 from "assets/icons/pool/tether.svg"
import img3 from "assets/icons/pool/usdc.svg"
import img5 from "assets/icons/pool/uni.svg"
import img6 from "assets/icons/pool/link.svg"
import img7 from "assets/icons/pool/wbtc.png"
import img8 from "assets/icons/pool/ape.png"
import img9 from "assets/icons/pool/weth.webp"

export type ICoin = {
  icon: string
  name: string
  token: string
  address: string
  id: string
  decimals: number
}

export const coins: ICoin[] = [
  {
    icon: img1,
    name: "Ethereum",
    token: "ETH",
    address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    decimals: 18,
    id: "ethereum",
  },
  {
    icon: img9,
    name: "Wrapped Ethereum",
    token: "WETH",
    address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    decimals: 18,
    id: "weth",
  },
  {
    icon: img2,
    name: "Tether",
    token: "USDT",
    address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    decimals: 6,
    id: "tether",
  },
  {
    icon: img3,
    name: "USD Coin",
    token: "USDC",
    address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    decimals: 6,
    id: "usd-coin",
  },
  {
    icon: img5,
    name: "Uniswap",
    token: "UNI",
    address: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    decimals: 18,
    id: "uniswap",
  },
  {
    icon: img6,
    name: "Chainlink",
    token: "LINK",
    address: "0x514910771af9ca656af840dff83e8264ecf986ca",
    decimals: 18,
    id: "chainlink",
  },
  {
    icon: img7,
    name: "Wrapped Bitcoin",
    token: "WBTC",
    address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    decimals: 8,
    id: "wrapped-bitcoin",
  },
  {
    icon: img8,
    name: "ApeCoin",
    token: "APE",
    address: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    decimals: 18,
    id: "apecoin",
  },
]

export const coinsLimit: ICoin[] = [
  {
    icon: img9,
    name: "Wrapped Ethereum",
    token: "WETH",
    address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    decimals: 18,
    id: "weth",
  },
  {
    icon: img2,
    name: "Tether",
    token: "USDT",
    address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    decimals: 6,
    id: "tether",
  },
  {
    icon: img3,
    name: "USD Coin",
    token: "USDC",
    address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    decimals: 6,
    id: "usd-coin",
  },
  {
    icon: img5,
    name: "Uniswap",
    token: "UNI",
    address: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    decimals: 18,
    id: "uniswap",
  },
  {
    icon: img6,
    name: "Chainlink",
    token: "LINK",
    address: "0x514910771af9ca656af840dff83e8264ecf986ca",
    decimals: 18,
    id: "chainlink",
  },
  {
    icon: img7,
    name: "Wrapped Bitcoin",
    token: "WBTC",
    address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    decimals: 8,
    id: "wrapped-bitcoin",
  },
  {
    icon: img8,
    name: "ApeCoin",
    token: "APE",
    address: "0x4d224452801aced8b2f0aebe155379bb5d594381",
    decimals: 18,
    id: "apecoin",
  },
]