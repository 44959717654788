import React from "react"
import { Header, Footer } from "components"

import "./Team.scss"
import imgMainBgSrc from "assets/images/main-bg.webp"
import imgTeam1Src from "assets/images/team/1.jpeg"
import { data } from "./data"

export const Team = (): JSX.Element => {

  return (
    <div className="team" style={{backgroundImage: `url(${imgMainBgSrc})`}}>
      <Header/>
      <div className='news-prices'>
        {
          // @ts-ignore
          (<coingecko-coin-price-marquee-widget coin-ids="bitcoin,ethereum,weth,binancecoin,usd-coin,uniswap,chainlink,wrapped-bitcoin,apecoin,tether,pancakeswap-token,baby-doge-coin,trust-wallet-token,stepn,coin98,aptos,optimism,matic-network,avalanche-2,arbitrum,chainlink,manta-network,fantom,dydx-chain" currency="usd"
                                                font-color="#ffffff"
                                                background-color="#030304"
                                                locale="en"/>)
        }
      </div>

      <div className="team-content">
        <h1 className="team-content-title">VaultEscrow Team</h1>
        <div className="team-content-item-big">
          <img src={imgTeam1Src} loading="lazy" alt="team" className="team-content-item-big-img"/>
          <dt className="team-content-item-big-title">Jorge Alamos</dt>
          <dd className="team-content-item-big-desc">Founder & CTO</dd>
        </div>
        <div className="team-content-wrap">
          {data.map((item, index) => (
            <div className="team-content-item" key={index}>
              <img src={item.img} loading="lazy" alt="team" className="team-content-item-img"/>
              <dt className="team-content-item-title">{item.title}</dt>
              <dd className="team-content-item-desc">{item.desc}</dd>
            </div>
          ))}
        </div>
      </div>
      <Footer/>
    </div>
  )
}
