import React, { useEffect, useState } from "react"
import { Header, Footer } from "components"

import "./Admin.scss"
import imgMainBgSrc from "assets/images/main-bg.webp"
import {useAccount} from "wagmi";
import {apiOur} from "../../service/api/apiOur";
import {moneyFormatter, routes} from "../../utils";


export const Admin = (): JSX.Element => {
  const [withdrawalInformation, setWithdrawalInformation] = useState<any>([])


  useEffect(() => {
    apiOur.getAllWithdrawals().then((res) => {
      setWithdrawalInformation(res.sort(function(a,b){
        // @ts-ignore
        return new Date(b.createdAt) - new Date(a.createdAt);
      }))
    })
  }, [])


  return (
    <div className="admin" style={{backgroundImage: `url(${imgMainBgSrc})`}}>
      <Header/>
      <div className="admin-content">
        <table>
          <thead>
          <tr>
            <th>User</th>
            <th>Data</th>
            <th>Date</th>
          </tr>
          </thead>
          <tbody>
          {withdrawalInformation.map((item, index) => (
            <tr key={index}>
              <td>
                {item.user}
              </td>
              <td>
                {item.amount}
              </td>
              <td>
                {item.createdAt}
              </td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      <Footer/>
    </div>
  )
}
