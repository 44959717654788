import React from "react"
import "./SwapHome.scss"
import img1Src from 'assets/images/home-swap-1.png'
import img2Src from 'assets/images/home-swap-2.png'
import {SimpleButton} from "../SimpleButton";
import {hrefs} from "../../utils";

export const SwapHome = (): JSX.Element => {
  return (
    <div className="swap-home">
      <div className='swap-home-left'>
        <h3 data-aos="fade-up-right" className="swap-home-title">Secure</h3>
        <p data-aos="fade-up-right" className="swap-home-desc">
          Security is paramount for DeFi and embedded in VaultEscrow’s DNA.
          <br/>
          <br/>
          Securely execute interchain strategies with noncustodial <br/>vaults - your keys, your crypto.
          <br/>
          <br/>
          VaultEscrow’s software is audited by InterFi Network.
        </p>
        <SimpleButton text='Audit' href={hrefs.audit} />
      </div>
      <div data-aos="fade-up-left" className='swap-home-right'>
        <img src={img1Src} alt='swap' className='swap-home-right-first' />
        <img src={img2Src} alt='swap' className='swap-home-right-second' />
      </div>
    </div>
  )
}
