export const routes = {
  index: "/",

  farms: "/VaultEscrow",
  team: "/team",
  faq: "/faq",
  terms: "/terms",
  privacy: "/privacy",
  cookies: "/cookies",
  referral: "/referral",
  pool: "/pool",
  swap: "/swap",
  news: "/news",
  swapInfo: "/analytics",
  token: "/token",
  poolInfo: "/pool-info",
  docs: "/litepaper",
  stake: "/stake",
  earn: "/earn",
  roadmap: "/roadmap",
  adminPanel: "/admin-panel-ajwknfeicrjjjjcoqkdks",
}
