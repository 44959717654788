export const hrefs = {
  facebook: "",
  instagram: "",
  twitter: "https://twitter.com/VaultEscrow_",
  youtube: "",
  discord: "",
  scan: "",
  telegram: "https://t.me/VaultEscrow",
  linkedIn: "",
  reddit: "",
  medium: "",
  address: "",
  docs: "",
  whitePaper: "",
  contract: "",
  audit:
    "https://github.com/interfinetwork/project-delivery-data/blob/main/VaultEscrow/VaultEscrow_AuditReport_InterFi.pdf",
}
